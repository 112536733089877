import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuckzyDocument } from 'app/models/buckzy-document/buckzy-document.model';
import { SettlementAccount } from 'app/models/reports/settlement-report/settlement-account/settlement-account.model';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { map, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Account } from '../../models/funds/account.model';
import { BankAccount } from '../../models/funds/bank.model';
import { Party } from '../../models/users/party.model';
import { ApiGetAccountParams } from 'app/models/clients/account/api-get-account-params.model';
import { PaginatedAccountResponse } from 'app/models/clients/account/paginated-account-response.model';

const PARTY_URI = '/v1/parties';
const PARTY_BULKS_URI = '/v1/party-bulks';
const ACCOUNTS_URI = '/v1/accounts';
const COUNTRY_URI = '/v1/countries';

@Injectable({
  providedIn: 'root',
})
export class PartyService {
  profile: any;
  ready = false;
  base64Image: any;

  constructor(private http: HttpClient) { }

//--------------------------------------------------------- Party CRUD Operations-----------------------------------------------//
  getMyProfile(): Observable<HttpResponse<Party>> {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    const urlParams = new HttpParams();
    return this.http.get<Party>( environment.BASE_URL + PARTY_URI + '/me',
      { params : urlParams, headers : h, observe: 'response'} );
  }

  getMyHash(): Observable<HttpResponse<{hash:string}>> {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    const urlParams = new HttpParams();
    return this.http.get<{hash:string}>( environment.BASE_URL + PARTY_URI + '/me/hash',
    { params : urlParams, headers : h, observe: 'response'} );
  }

  updateMyProfile(request): Observable<HttpResponse<Party>> {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    // const urlParams = new HttpParams();
    const url = environment.BASE_URL + PARTY_URI + '/me';
    return this.http.put<Party>( url, request, { headers : h, observe: 'response'} );
  }

  getMyProfilePicture(): Observable<ArrayBuffer> {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    const urlParams = new HttpParams();
    return this.http.get( environment.BASE_URL + PARTY_URI + '/me/picture',
      { params: urlParams, headers: h, responseType: 'arraybuffer' });
  }

  updateMyProfilePicture(dataObject) {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    const url = environment.BASE_URL + PARTY_URI + '/me/picture';
    return this.http.put( url, dataObject );
  }

  createPartyForFirstTime(request): Observable<HttpResponse<Party>> {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    // const urlParams = new HttpParams();
    const url = environment.BASE_URL + PARTY_URI + '/me';
    return this.http.post<Party>( url, request, { headers : h, observe: 'response'} );
  }

  getParties() {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    const urlParams = new HttpParams();
    return this.http.get<Party>( environment.BASE_URL + PARTY_URI,
      { params : urlParams, headers : h, observe: 'response'} );
  }
  
  // Config fullname for parties
  setPartiesNames(partyList: Array<any>): Array<any> {
    for(var i = 0; i < partyList.length; i++) {
      if(!!partyList[i].legalName) {
        partyList[i].name = partyList[i].legalName;
      }
      else if(!!partyList[i].firstName && !!partyList[i].lastName) {
        partyList[i].name = [partyList[i].firstName, partyList[i].middleName, partyList[i].lastName]
        .filter(str => !!str && str !== ' ').join(' ');
      }
      else {
        partyList[i].name = "Name Missing";
      }
    }

    return partyList;
  }

//-------------------------------------------------------Party's Account CRUD Operations-------------------------------------//

  getMyAccounts(): Observable<HttpResponse<Account[]>> {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    const urlParams = new HttpParams();
    return this.http.get<Account[]>( environment.BASE_URL + PARTY_URI + '/me/accounts?tp=bank',
      { params : urlParams, headers : h, observe: 'response'} );
  }
  deleteMyAccounts(accountId) {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    const urlParams = new HttpParams();
    return this.http.delete( environment.BASE_URL + PARTY_URI + '/me/accounts/' + accountId ,
      { params : urlParams, headers : h, observe: 'response'} );
  }

  addMyAccount(bankAccount: BankAccount): Observable<HttpResponse<Account>> {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    const url = environment.BASE_URL + PARTY_URI + '/me/accounts?tp=bank';
    return this.http.post<Account>( url, bankAccount, { headers : h, observe: 'response'} );
  }

  getAccountTypes() {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    const urlParams = new HttpParams();
    return this.http.get( environment.BASE_URL + ACCOUNTS_URI + '/tp',
      { params : urlParams, headers : h, observe: 'response'} );
  }

  getAccounts(requestParamObj?: ApiGetAccountParams): Observable<PaginatedAccountResponse> {
    var params = new HttpParams();
    if(!requestParamObj) {
      requestParamObj = new ApiGetAccountParams();
      requestParamObj.size = 9999;
    }
    for(let key of Object.keys(requestParamObj)) {
      if(requestParamObj[key] != null) {
        params = params.set(key, requestParamObj[key]);
      }
    }

    const options = {
      params: params
    }

    return this.http.get<PaginatedAccountResponse>( environment.BASE_URL + ACCOUNTS_URI, options );
  }

  getSettlementAccounts(): Observable<SettlementAccount[]> {
    const headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('access-control-expose-headers', '*');

    const params = new HttpParams().set('isSettlementAccount', `${true}`); 

    const options = {
      headers: headers,
      observe: 'response' as const,
      params: params
    }

    return this.http.get<SettlementAccount[]>( environment.BASE_URL + ACCOUNTS_URI, options )
    .pipe(
      take(1),
      map(response => response.body)
    );
  }

  updateMyAccount(accountId: number, account) {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    const url = environment.BASE_URL + PARTY_URI + '/me/accounts/' + accountId + '?tp=bank';
    return this.http.put( url, account, { headers : h, observe: 'response'} );
  }

  setPreferredAccount(accountId: string) {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    const url = environment.BASE_URL + PARTY_URI + "/me/accounts/" + accountId + "?preferred=true";
    return this.http.put( url, { headers : h, observe: 'response'} );
  }

  setRecipientPreferredAccount(accountId: string, ownerId: string, clientPartyId = 'me') {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    const url = environment.BASE_URL + PARTY_URI + "/" + clientPartyId + "/recipients/" + ownerId + '/accounts/' + accountId + '?preferred=true';
    return this.http.put( url, { headers : h, observe: 'response'} );
  }

//---------------------------------------------Profile's Drop Down APIs----------------------------------------------------------//

  getAllCountries() {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    const urlParams = new HttpParams();
    return this.http.get( environment.BASE_URL + COUNTRY_URI,
      { params: urlParams, headers: h, observe: 'response' });
  }

  getCities(countrycode: string, statecode: string) {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    const urlParams = new HttpParams();
    return this.http.get( environment.BASE_URL + COUNTRY_URI + '/' + countrycode + '/states/' + statecode + '/cities',
      { params: urlParams, headers: h, observe: 'response' });
  }

  changePassword(request) {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    const url = environment.BASE_URL + PARTY_URI + '/me/password';
    return this.http.put<Party>( url, request, { headers : h, observe: 'response'} );
  }

  //---------------------------------------------Party Document APIs----------------------------------------------------------//

  getPartyDocuments(partyId: number): Observable<BuckzyDocument[]> {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };

    const urlParams = new HttpParams();
    const URL = `${environment.BASE_URL}${PARTY_URI}/${partyId}/documents`;
    
    return this.http.get<BuckzyDocument[]>( URL, { params: urlParams, headers: h, observe: 'response' }).map(response => {
      let arrayResonse = response.body.map(document => {
        return new BuckzyDocument(
          document?.id, 
          document?.type, 
          document?.fileName, 
          document?.submittedAt, 
          document?.submittedBy
        );
      });
      return arrayResonse;
    });
  }

  downloadPartyDocument(partyId: number, fileId: number) {
    const URL = `${environment.BASE_URL}${PARTY_URI}/${partyId}/documents/${fileId}/file`;
    return this.http.get( URL, { responseType: 'blob' } );
  }

  addPartyDocument(partyId: number, file: File, invoiceType: string): Observable<HttpResponse<any>> {
    const h = {
      // 'Content-Type' : 'multipart/form-data; boundary=<calculated when request is sent>',
      // 'access-control-expose-headers' : '*',
    };
    const multiPartForm = new FormData();
    multiPartForm.append('documentFiles', file);
    multiPartForm.append('fileTp', invoiceType);

    const URL = `${environment.BASE_URL}${PARTY_URI}/${partyId}/documents:multi-file`

    return this.http.post<any>( URL , multiPartForm, { headers : h, observe: 'response'} );
  }

  catchError(error) {
    console.log('Http Error Ecountered' + error);
  }

//--------------------------------------------------------- Party-Bulk Operations-----------------------------------------------//

  createPartyBulk(file: File): Observable<any> {
    const h = {
      // IMPORTANT:
      //    DO NOT SET CONTENT-TYPE OR ELSE FILE UPLOAD WILL FAIL. HTTP CLIENT WILL SET THE CONTENT-TYPE AUTOMATICALLY!
      //    ===========================================================================================================
    };
    const multiPartForm = new FormData();
    multiPartForm.append('file', file);

    return this.http.post( environment.BASE_URL + PARTY_BULKS_URI, multiPartForm, { headers : h, observe: 'response' } );
  }

  validatePartyBulk(file: File): Observable<any> {
    const h = {
      // IMPORTANT:
      //    DO NOT SET CONTENT-TYPE OR ELSE FILE UPLOAD WILL FAIL. HTTP CLIENT WILL SET THE CONTENT-TYPE AUTOMATICALLY!
      //    ===========================================================================================================
    };
    const multiPartForm = new FormData();
    multiPartForm.append('file', file);

    const url = `${environment.BASE_URL}${PARTY_BULKS_URI}/analysis`;

    return this.http.post( url, multiPartForm, { headers : h, observe: 'response' } );
  }

  getPartyBulks(page: number, size: number, fileName?: string, submittedFromDate?: string, submittedToDate?: string ): Observable<HttpResponse<any>> {

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders
      .set('Content-Type', 'application/json')
      .set('access-control-expose-headers','*');

    let httpParams = new HttpParams();
    httpParams = httpParams.set('page', `${page}`);
    httpParams = httpParams.set('size', `${size}`);

    if (fileName) {
      httpParams = httpParams.set('fileName', fileName);
    }

    if (submittedFromDate) {
      httpParams = httpParams.set('submittedFrom', submittedFromDate);
    }

    if (submittedToDate) {
      httpParams = httpParams.set('submittedTo', submittedToDate);
    }

    const options = {
      headers: httpHeaders,
      params: httpParams,
      observe: 'response' as const
    }

    const url = `${environment.BASE_URL}${PARTY_BULKS_URI}`;

    return this.http.get<any>( url, options );
  }

  getBulkCSV(bulkId:string) {

    const url = `${environment.BASE_URL}${PARTY_BULKS_URI}/${bulkId}/csv`;
    return this.http.get( url, { responseType: 'blob' } );
  }

}
