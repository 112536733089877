import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: NbAuthService, private router: Router) {}

  canActivate() {
    /*
    console.log("AUTH GUARD");
    this.authService.isAuthenticatedOrRefresh().subscribe(data => {
      console.log(data);
    }, err => {
      console.log(err);
    });

    this.authService.getToken().subscribe(token => {
      console.log(token);
    }, err => {
      console.log(err);
    });
    */
    return this.authService.isAuthenticatedOrRefresh()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
          }
        }),
      );
  }
}