import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[buckDragDropDirective]',
})
export class BuckDragDropDirective {

  constructor() { }

  @Input() isDisabled;
  @Output() onFileDropped = new EventEmitter<any>();

  @HostBinding('class.drag-over') dragOver = false;
  @HostBinding('class.dropped') dropped = false;
  /**
   * Dragover listener
   * @param evt
   */
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    // console.log(this.isDisabled);
    if(this.isDisabled)
      this.dragOver = false;
    else
      this.dragOver = true;
  }

  /**
   * Dragleave listener
   * @param evt
   */
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragOver = false;
  }

  /**
   * Drop listener
   * @param evt
   */
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files);
    }
    // console.log(this.isDisabled);
    this.dragOver = false;
    if(this.isDisabled)
      this.dropped = false;
    else
      this.dropped = true;
  }
}
