<div class="container-account-balance" *ngIf="accountsArray.length > 0">
  <div class="container-account-selector">
    <nb-select [(ngModel)]="selectedAccountIndex" class="width-90-percent" (ngModelChange)="fireSelectedAccount(selectedAccountIndex);">
      <nb-select-label [nbSpinner]="!accountsArray[selectedAccountIndex]?.accountId" nbSpinnerStatus="primary">
        <!-- <span>
          <img *ngIf="accountsArray[selectedAccountIndex]?.financialInstitutionDetails?.countryCode" [src]="'assets/flags/' + accountsArray[selectedAccountIndex].financialInstitutionDetails.countryCode.toLowerCase() + '.png'" width="25">
        </span>
        &nbsp; -->
        <span class="align-middle" *ngIf="accountsArray[selectedAccountIndex]?.accountId">{{accountsArray[selectedAccountIndex]?.currencyCode}} ( **** {{ (accountsArray[selectedAccountIndex]?.financialInstitutionDetails?.accountNumber?.length > 3) ? (accountsArray[selectedAccountIndex]?.financialInstitutionDetails?.accountNumber | slice:-4) : accountsArray[selectedAccountIndex]?.financialInstitutionDetails?.accountNumber}} )</span>
      </nb-select-label>
      <nb-option *ngFor = "let account of accountsArray; let i=index;" [value]="i">
        <!-- <span class="custom-margin-top">
            <img [src]="'assets/flags/' + (accountsArray[i].financialInstitutionDetails?.countryCode | lowercase) + '.png'" width="25">
        </span>
        &nbsp;&nbsp; -->
        <div class="account-option">
          <div>
            {{account?.currencyCode}} ( **** {{ (account?.financialInstitutionDetails?.accountNumber?.length > 3) ? (account?.financialInstitutionDetails?.accountNumber | slice:-4) : account?.financialInstitutionDetails?.accountNumber}} )
          </div>
          <div>
            {{account?.nickName}}
          </div>
        </div>
      </nb-option>

    </nb-select>
  </div>
  <div class="account-balance">
    <span>Balance: </span> &nbsp;
    <span *ngIf="updatedAccountFetched">{{accountsArray[selectedAccountIndex]?.balance | currency:accountsArray[selectedAccountIndex]?.currencyCode:'symbol-narrow'}}</span>
    <span *ngIf="!updatedAccountFetched">---</span>
  </div>
</div>
