<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle hidden-xl-up d-xl-none">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="bucklogo" href="#" (click)="navigateHome()"><img src="assets/images/logo.png"></a>
  </div>
  
  <ngx-layout-direction-switcher *ngIf="false" class="direction-switcher"></ngx-layout-direction-switcher>
</div>

<div class="header-container no-print">
 
  <nb-select *ngIf="false" class="theme" (selectedChange)="switchLang($event)" status="primary"  [selected]="selectedLang">
    <!-- <option *ngFor="let language of translate.getLangs()" [value]="language" [selected]="language === translate.currentLang">
      {{ language }}
    </option> -->
    <nb-option *ngFor="let language of languageArray" [value]="language.code">{{ language.name }}</nb-option>
  </nb-select>&nbsp;&nbsp;

  <nb-select *ngIf="false" class="theme" [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>

  
  <!-- <img [src] = "user?.picture" width = 50>
  <nb-actions size = "giant">
    <nb-action class="user-action">
      <label  style="margin: 0 10px 0 10px;">{{compObj?.legalName}}</label>
      <button nbButton status="primary" [nbContextMenu]="userMenu" style="border-radius: 50%;">
        <nb-icon icon="arrow-ios-downward" style="color: white;"></nb-icon>
      </button>
      <label  style="margin: 0 10px 0 10px;">{{user?.name}}</label>
    </nb-action>
  </nb-actions> -->
  <div class="row" style="width: 500px;">
    <div class="col-2" style="padding-top: 10px;">
      <img [src] = "user?.picture" width = 50>
    </div>
    <div class="col" style="padding-top: 10px;">
      <div class="row" style="margin-bottom: -10px;">
        <div class="col">
          <label style="font-weight: bold;">{{compObj?.legalName}}</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label style="font-size: 12px; font-weight: bold;">{{user?.name}}</label>
        </div>
      </div>
    </div>
    <div class="col-4" style="padding-top: 10px;">
      <!-- <button nbButton status="primary" [nbContextMenu]="userMenu" style="border-radius: 50%;">
        <nb-icon icon="arrow-ios-downward" style="color: white;"></nb-icon>
      </button> -->
      <button nbButton outline ghost status="primary" [nbContextMenu]="userMenu" style="border-radius: 50%;" data-cy="header-button-usermenu">
        <nb-icon icon="arrow-ios-downward" ></nb-icon>
      </button>
    </div>
    
  </div>
</div>
