import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

const USER_URI = '/v1/users';

@Injectable({
  providedIn: 'root'
})
export class ReceivablesUserService {

  constructor( private http: HttpClient ) { }

  getUserProfile() {
    const h = {
        'Content-Type' : 'application/json',
        'access-control-expose-headers' : '*',
    };
    const URL = environment.BASE_URL + USER_URI + '/me/organization-profile';
    return this.http.get<any>( URL , { headers : h, observe: 'response'} );
  }
}