import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSelectorComponent } from './account-selector.component';
import { FormsModule } from '@angular/forms';
import { NbSelectModule, NbSpinnerModule } from '@nebular/theme';



@NgModule({
  declarations: [AccountSelectorComponent],
  imports: [
    CommonModule,
    FormsModule,
    NbSelectModule,
    NbSpinnerModule
  ],
  exports: [AccountSelectorComponent],
})
export class AccountSelectorModule { }
