import { Currency } from '../funds/currency.model';

export class Country {
	public callCode: string;
	public countryCode: string;
	public countryName: string;
	public currencies: Array<string>;
	public internationalDialPrefix: string;

	constructor(callCode?: string, countryCode?: string, countryName?: string, currencies?: Array<string>, internationalDialPrefix?: string) {
                this.callCode =  callCode;
                this.countryCode = countryCode;
		this.countryName = countryName;
		this.currencies = currencies;
		this.internationalDialPrefix = internationalDialPrefix;
    }
}
