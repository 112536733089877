export const BUCKZY_CONSTANTS = {
    COUNTRY_CODES: {
        CANADA: "ca",
        INDIA: "IN"
    },
    DIAL_CODES: {
        CANADA: "1"
    },
    CODE_TYPES: {
        DOCUMENT_TYPE: 'DOCUMENT_TYPE',
        LINE_OF_BUSINESS: 'LINE_OF_BUSINESS',
        SOURCE_OF_WEALTH: 'SOURCE_OF_WEALTH',
        CORPORATION_TYPE: 'CORPORATION_TYPE',
        ORGANIZATION_TYPE: 'ORGANIZATION_TYPE',
        OCCUPATION: 'OCCUPATION',
        RELATIONSHIP: 'RELATIONSHIP'
    },
    PAGES: {
        PARTY_DETAILS: 'PARTY_DETAILS'
    },
    PARTY: {
        PARTY_SIDE: {
            DEBTOR: "Debtor",
            CREDITOR: "Creditor",
            BENEFICIARY: "Beneficiary",
            SENDER: "Sender",
            RECEIVER: "Receiver"
        },
        PARTY_TYPE: {
            BUSINESS: {
                DESCRIPTION: 'Business',
                CODE: 'B'
            },
            INDIVIDUAL: {
                DESCRIPTION: 'Individual',
                CODE: 'I'
            }
        },
        ACTION: {
            UPDATE: 'Update',
            ADD: 'Add'
        },
    },
    URL: {
        RECEIVERS: '/pages/profile-management/receivers',
        SENDERS: '/pages/profile-management/senders',
        DASHBOARD: '/pages/payout-dashboard'
    },
    SORT: {
        ASCENDING: 'ASC',
        DESCENDING: 'DESC'
    },
    DATE_FORMAT: {
        ISO_DATE: 'yyyy-MM-dd'
    },
    LOCALE: {
        EN_CA: 'en-CA'
    }
}

export declare type CODE_TYPE = 'DOCUMENT_TYPE' | 'LINE_OF_BUSINESS' | 'SOURCE_OF_WEALTH' | 'ORGANIZATION_TYPE' | 'CORPORATION_TYPE' | 'OCCUPATION' |'RELATIONSHIP';