import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CODE_TYPE } from 'app/models/buckzy-constants/buckzy-constants.model';
import { BuckzyCode } from 'app/models/handle-party/buckzy-code.model';
import { WalletCurrency } from 'app/models/handle-party/wallet/currencies/wallet-currency.model';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Country } from 'app/models/users/country.model';

const CURRENCY_URI = '/v1/currencies';
const CLIENTS_URI = '/v1/clients';
const COUNTRY_URI = '/v1/countries';
const PURPOSE_URI = '/v1/purpose-codes';
const AGENT_URI = '/v1/agents';
const BUCKZY_CODES_URI = '/v1/buckzy-codes';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {

  constructor( private http: HttpClient ) { }

  getSupportingCurrencies(): Observable<HttpResponse<any>> {
    const h = {
        'Content-Type' : 'application/json',
        'access-control-expose-headers' : '*',
    };
    let URL = environment.BASE_URL + CURRENCY_URI + '?support=sending';

    return this.http.get<any>( URL , { headers : h, observe: 'response'} );
  }

  getWalletCurrencies(): Observable<WalletCurrency[]> {
    const h = {
        'Content-Type' : 'application/json',
        'access-control-expose-headers' : '*',
    };
    const URL = `${environment.BASE_URL}${CURRENCY_URI}?support=sending`;

    let currencyResponse = this.http.get<any[]>( URL , { headers : h, observe: 'response'} ).pipe(
      take(1),
      map(response => {
        return response.body
          .map(walletCurrency => {
            return new WalletCurrency(walletCurrency.currencyCode, walletCurrency.currencyName);
          })
          .filter((value, index, self) => {
            return index === self.findIndex((currency) => {
              return currency.currencyCode === value.currencyCode;
            });
          });
      })
    );

    return currencyResponse;
  }

  getPurpose(type): Observable<HttpResponse<any>> {
    const h = {
        'Content-Type' : 'application/json',
        'access-control-expose-headers' : '*',
    };
    let URL = environment.BASE_URL + PURPOSE_URI + '?tp=' + type;

    return this.http.get<any>( URL , { headers : h, observe: 'response'} );
  }

  getSupportingCountries(clientID?: string): Observable<HttpResponse<any>> {
    const h = {
        'Content-Type' : 'application/json',
        'access-control-expose-headers' : '*',
      };
      let URL = environment.BASE_URL + COUNTRY_URI + '?support=true';

      if(clientID) {
        URL = URL + '&clientId=' + clientID
      }

      return this.http.get<any>( URL , { headers : h, observe: 'response'} );
  }

  getSupportingReceivingCurrencies(): Observable<HttpResponse<any>> {
    const h = {
        'Content-Type' : 'application/json',
        'access-control-expose-headers' : '*',
      };
      let URL = environment.BASE_URL + CURRENCY_URI + '?support=receiving';

      return this.http.get<any>( URL , { headers : h, observe: 'response'} );
  }

  getProvinces(countryCode: string): Observable<HttpResponse<any>> {
    const h = {
        'Content-Type' : 'application/json',
        'access-control-expose-headers' : '*',
      };
      let URL = environment.BASE_URL + COUNTRY_URI + '/' + countryCode + '/states';

      return this.http.get<any>( URL , { headers : h, observe: 'response'} );
  }

  getCountries(): Observable<Country[]> {
    const headers = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };

    const URL = `${environment.BASE_URL}${COUNTRY_URI}`;
    const options = {
      headers: headers,
      observe: 'response' as const,
    }
    
    return this.http.get<Country[]>( URL, options ).pipe(
      map(response => {
        return response.body.map(country => {
          return new Country(
            country.callCode,
            country.countryCode,
            country.countryName,
            country.currencies,
            country.internationalDialPrefix);
        });
      })
    );
  }

  getClients(): Observable<HttpResponse<any>> {
    const h = {
        'Content-Type' : 'application/json',
        'access-control-expose-headers' : '*',
      };
      const URL = environment.BASE_URL + CLIENTS_URI;
      return this.http.get<any>( URL , { headers : h, observe: 'response'} );
  }

  getSupportingProvinces(country?: string): Observable<HttpResponse<any>> {
    const h = {
        'Content-Type' : 'application/json',
        'access-control-expose-headers' : '*',
      };
      let URL = environment.BASE_URL + COUNTRY_URI + '/' + country + '/states';

      return this.http.get<any>( URL , { headers : h, observe: 'response'} );
  }

  getSupportingCities(country?: string, province?: string): Observable<HttpResponse<any>> {
    const h = {
        'Content-Type' : 'application/json',
        'access-control-expose-headers' : '*',
      };
      let URL = environment.BASE_URL + COUNTRY_URI + '/' + country + '/states/' + province + '/cities';

      return this.http.get<any>( URL , { headers : h, observe: 'response'} );
  }

  getAgentInformation(fromCurrencyCode: string, toCurrencyCode: string, toCountryCode: string, disbursementMethod: string): Observable<HttpResponse<any>> {
    const h = {
        'Content-Type' : 'application/json',
        'access-control-expose-headers' : '*',
      };
      const URL = environment.BASE_URL + AGENT_URI + "?fromCurrencyCode=" + fromCurrencyCode + "&toCurrencyCode=" + toCurrencyCode + "&toCountryCode=" + toCountryCode + "&disbursementMethod=" + disbursementMethod;
      return this.http.get<any>( URL , { headers : h, observe: 'response'} );
  }

  getBuckzyCodes(codeType: CODE_TYPE): Observable<BuckzyCode[]> {

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders
      .set('Content-Type', 'application/json')
      .set('access-control-expose-headers', '*');

    let httpParams = new HttpParams();
    httpParams =  httpParams.set('category', codeType);

    const options = {
      headers: httpHeaders,
      params: httpParams,
      observe: 'response' as const
    }

    const URL = `${environment.BASE_URL}${BUCKZY_CODES_URI}`; 
    
    return this.http.get<any[]>( URL, options ).map(response => {
      return response.body.map(buckzyCode => {
        return new BuckzyCode(buckzyCode.code, buckzyCode.description);
      });
    });
  }
 
}