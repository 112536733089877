import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { environment } from "../../../environments/environment";
import { NbAuthService, NbAuthToken } from "@nebular/auth";
import { switchMap, catchError } from "rxjs/operators";
import { HelperService } from "../helper/helper.service";
import { throwError } from "rxjs";
import { Router } from "@angular/router";

@Injectable()
export class ApiKeyHttpInterceptor implements HttpInterceptor {
    token: string;
    tokenValid: boolean = true;
    unAuthorizedError: number = 401;
    constructor(
        private auth: NbAuthService,
        private helperService: HelperService,
        private nbAuthService: NbAuthService,
        private router: Router
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let authHeader =
            "Basic " +
            btoa(environment.AUTH.CLIENT_ID + ":" + environment.AUTH.CLIENT_PWD);
        if (request.url !== environment.AUTH.AUTH_BASE + environment.AUTH.AUTH_URL) {
            return this.auth.getToken().pipe(
                switchMap((token: NbAuthToken) => {
                    authHeader = "Bearer " + token.getValue();
                    request = request.clone({
                        setHeaders: {
                            Authorization: authHeader,
                        },
                        setParams: {
                            apikey: environment.AUTH.API_KEY,
                        },
                    });
                    return next.handle(request).pipe(
                        catchError((error) => {
                            if (
                                error instanceof HttpErrorResponse &&
                                !request.url.includes(environment.AUTH.AUTH_URL) &&
                                !this.router.url.includes('auth/login') &&
                                error.status === this.unAuthorizedError
                            ) {
                                // try using refresh token strategy (if not expired) to obtain access token
                                // and log user out if valid auth token cannot be obtained 
                                this.nbAuthService.isAuthenticatedOrRefresh().subscribe(
                                    (isValid) => {
                                        if (!isValid) {
                                            this.helperService.showAlertBox();
                                        } else {
                                            return;
                                        }
                                    },
                                    (err) => {
                                        this.helperService.showAlertBox();
                                    }
                                );
                            }
                            return throwError(error);
                        })
                    );
                })
            );
        } else {
            request = request.clone({
                setHeaders: {
                    Authorization: authHeader,
                },
                setParams: {
                    apikey: environment.AUTH.API_KEY,
                },
            });
            return next.handle(request);
        }
    }
}
