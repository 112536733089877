import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'alertt',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss'],
})
export class AlertBoxComponent {
  header: string = "Session Expired";
  message: string = "Your session is expired. You are required to login again.";
  yesButtonText: string = "OK";

  constructor(protected dialogsRef: NbDialogRef<AlertBoxComponent>) {
  }

  setUp( header = "Session Expired", message = "Your session is expired. You are required to login again.", yesButtonText = "OK") {
    this.header = header;
    this.message = message;
    this.yesButtonText = yesButtonText;
  }
  close() {
    this.dialogsRef.close("No");
  }

  continue() {
    this.dialogsRef.close("Yes");
  }
}