import { AfterContentChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { HelperService } from '../../../services/helper/helper.service';
import { UserData } from '../../../@core/data/users';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NbAuthService } from '@nebular/auth';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PartyService } from '../../../services/party/party.service';
import { ReceivablesUserService } from '../../../services/receivables/user/user.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy, AfterContentChecked {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  languageArray = [
    {
      code: "en",
      name: "English"
    },
    {
      code: "nl",
      name: "Dutch"
    }
  ]

  selectedLang: any;

  themes = [
    {
      value: 'buckzy-v1',
      name: 'Buckzy',
    },
    {
      value: 'buckzy-v1-dark',
      name: 'Buckzy dark',
    },
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'buckzy-v1';

  userMenu = [ { title: 'Help', icon: 'question-mark-circle-outline' }, { title: 'Settings', icon: 'settings-outline' }, { title: 'Log out', icon: 'log-out-outline' } ];
  profile: any;
  userObj = {
    name: null,
    picture: null
  }
  compObj: any = {};

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private authService: NbAuthService,
              private router: Router,
              private breakpointService: NbMediaBreakpointsService,
              public translate: TranslateService,
              private helperService: HelperService,
              private partyService: PartyService,
              private receivablesUserService: ReceivablesUserService
            ) {

              this.selectedLang = this.helperService.getLanguage();
              if(this.selectedLang == undefined)
                this.selectedLang = navigator.language;

              this.switchLang(this.selectedLang);
              this.getProfile();
              this.getUserOrganization();
  }
  ngAfterContentChecked(): void {
    var div;
    try {
      div = document.getElementsByClassName('user-picture initials ng-star-inserted');
      // console.log(div[0]);
      div[0].style = "background-color";
    } catch(e) {}
  }

  getUserOrganization() {
    this.receivablesUserService.getUserProfile().subscribe(response => {
      // console.log(response.body);
      this.compObj = response.body;
    }, error => {
      console.log(error);
    })
  }

  getProfile() {
    this.partyService.getMyProfile().subscribe(response => {
      // console.log(response.body);
      this.profile = response.body;
      if(this.profile.tp == "I") {
        if(this.profile.firstName && this.profile.lastName)
          this.userObj.name = this.profile.firstName + ' ' + this.profile.lastName;
      }
      else if(this.profile.tp == "B") {
        if(this.profile.legalName)
          this.userObj.name = this.profile.legalName;
      }
      this.user = this.userObj;
      this.getProfilePicture();
    }, error => {
      console.log(error);
    });
  }

  getProfilePicture() {
    this.partyService.getMyProfilePicture().subscribe(response => {
      try {
        let b64encoded = btoa([].reduce.call(new Uint8Array(response), function(p, c){return p + String.fromCharCode(c); }, ''));
        let mimetype="image/jpeg";
        this.userObj.picture = "data:"+mimetype+";base64,"+b64encoded;
        this.user = this.userObj;
      } catch (exp) {}
    }, err => {
      // console.log(err);
    });
  }

  //Translation
  switchLang(language: string) {
    // console.log(language);
    this.translate.use(language);
    this.helperService.setLanguage(language);
  }

  logout() {
    this.authService.logout('email').subscribe(result => {
      this.router.navigateByUrl('/auth/login');
    }, err => {
      console.log(err);
      this.router.navigateByUrl('/auth/login');
    });
  }
  
  ngOnInit() {
    
    this.menuService.onItemClick().subscribe( obj => {
      if (obj.item.title === "Settings") {
        this.router.navigateByUrl('pages/my-profile/change-passwords');
      } else if (obj.item.title === "Log out") {
        this.logout();
      }
      else if (obj.item.title === "Help") {
        this.router.navigateByUrl('pages/help');
      }
    });
    this.currentTheme = this.themeService.currentTheme;
    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = user);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
