import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbAuthOAuth2JWTToken, NbAuthOAuth2Token, NbDummyAuthStrategy, NbOAuth2AuthStrategy, NbOAuth2GrantType, NbOAuth2ResponseType } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { AnalyticsService, SeoService } from './utils';
import { UserData } from './data/users';
import { UserService } from './mock/users.service';
import { MockDataModule } from './mock/mock-data.module';

import { environment } from '../../environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiKeyHttpInterceptor } from 'app/services/interceptors/apiKeyHttpInterceptor.service';

const socialLinks = [
  {
    url: 'https://linkedin.com/company/buckzypayments',
    target: '_blank',
    icon: 'linkedin',
  },
  {
    url: 'https://www.facebook.com/BuckzyPaymentsInc',
    target: '_blank',
    icon: 'facebook',
  },
  {
    url: 'https://twitter.com/BuckzyPayments',
    target: '_blank',
    icon: 'twitter',
  },
];

const DATA_SERVICES = [
  { provide: UserData, useClass: UserService },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({

    strategies: [
      /*
      NbDummyAuthStrategy.setup({
        name: 'email',
        delay: 3000,
      }),
      */
      NbOAuth2AuthStrategy.setup({
        baseEndpoint: environment.AUTH.AUTH_BASE,
        name: 'email',
        clientId: environment.AUTH.CLIENT_ID,
        clientSecret: environment.AUTH.CLIENT_PWD,
        authorize: {
          endpoint: environment.AUTH.AUTH_URL,
          responseType: NbOAuth2ResponseType.TOKEN,
          //scope: 'aws.cognito.signin.user.admin',
          //redirectUri: this.router.url + '/callback',
        },
        redirect: {
          // success: '/pages/dashboard',
          // success: '/pages/mto/dashboard',
          success: '/'
        },
        token: {
          endpoint: environment.AUTH.AUTH_URL,
          grantType: NbOAuth2GrantType.PASSWORD,
          class: NbAuthOAuth2JWTToken, // NbAuthOAuth2Token
          //redirectUri: this.router.url + '/callback',
        },
        refresh: {
          endpoint: environment.AUTH.AUTH_URL,
          grantType: NbOAuth2GrantType.REFRESH_TOKEN,
        },
      }),
    ],
    forms: {
      login: {
        socialLinks: socialLinks,
      },
      register: {
        socialLinks: socialLinks,
      },
    },
  }).providers,

  // NbSecurityModule.forRoot({
  //   accessControl: {
  //     guest: {
  //       view: '*',
  //     },
  //     user: {
  //       parent: 'guest',
  //       create: '*',
  //       edit: '*',
  //       remove: '*',
  //     },
  //     manager: {
  //       parent: 'guest',
  //       create: ['upload_csv', 'execute_csv'],
  //     },
  //     operator: {
  //       parent: 'guest',
  //       create: ['upload_csv'],
  //     },
  //     finance: {
  //       view: ['reports', 'account_statement', 'transaction_history'],
  //     },
  //     'ROLE_CHECKER': {
  //       batch: ['cancel', 'execute'],
  //     },
  //     maker: {
  //       batch: ['cancel', 'upload'],
  //     },
  //     admin: {
  //       parent: 'maker',
  //       batch: ['execute', '*'],
  //     },
  //   },
  // }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  SeoService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
        { provide: HTTP_INTERCEPTORS, useClass: ApiKeyHttpInterceptor, multi: true },
      ],
    };
  }
}
