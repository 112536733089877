

<section class="fulwidth cmmonbannerWrap scrollItm">
	<div class="page_container">
		<aside> </aside>
	    <aside>
	        <h1>Terms and Conditions</h1>
	    </aside>
	</div>
</section>


	<section id="primary" class="content-area fulwidth commonPageContent">
		<main id="main" class="site-main page_container">

			<h2>Buckzy Terms and Conditions</h2>
<p>Buckzy Payments Inc (“Buckzy”), a Canadian fintech company headquartered in Toronto providing online digital payment PRODUCTS AND SERVICES are subject to the below specified terms and conditions. (“AGREEMENT”) and applicable law.</p>
<p>More information about our products are services can be obtained at www.buckzy.ca or www.buckzy.net. Service availability depends on certain transaction conditions including the Service selected (including the special terms applicable to each Service, as set forth below), amount sent, destination country, currency availability, regulatory policies, identification requirements and geographic location constraints (collectively, “Restrictions&#8217;). The designated recipient (“Receiver&#8217;) may generally receive funds sent by a Buckzy consumer (“You&#8217;) through online channel in the Destination/Expected Payout bank account or a 3rd party wallet account.  The Receiver will normally receive funds sent by You online, funds may be credited to a bank, mobile Wallet, or similar account. Under some circumstances, the Receiver may be able to elect a payout method that differs from the payout method You specify; You authorize Buckzy to honor the Receiver&#8217;s election of payout method. Receivers who receive funds through a payout method could may incur additional fees based on the election of the method to access funds due to the reason that certain channels, countries and/or jurisdictions may impose a tax, fee and/or tariff on the Receiver’s receipt of, or access to, transferred funds. Transactions:</p>
<ul>
<li>which exceed above certain amounts</li>
<li>to certain destination countries</li>
<li>that implicate certain regulatory issues</li>
<li>sent through delayed options may take longer, be subject to dollar limits or be subject to additional Restrictions and reporting guidelines.</li>
</ul>
<p>In some destinations the Receiver may be required to provide additional proof of identification, a test question answer or both to receive funds.</p>
<p><strong>ADMINISTRATION CHARGE:</strong> When sending money using email, the transactions not picked up or canceled by You within one year of the send date will be assessed a non-refundable administration charge of up to fifty cents (CAD) per month from the send date, not to exceed forty-two dollars (CAD), which will be deducted from the amount sent. All administration charges include 5% GST, 7.5% QST or 13% HST as applicable, depending on the province from where the money transfer originated.
</p>
<p><strong>REFUNDS:</strong> PRINCIPAL REFUNDS and cancellation of the money transfer can only be made if payment has not been deposited to the beneficiary’s bank account. If Receiver rejects Your funds or You request a refund, Buckzy may charge You a fee to refund the principal amount back to You.</p>
<p><strong>IDENTITY VERIFICATION:</strong> Buckzy has engaged Securefact Transaction Services Inc. (Securefact”) to provide identity verification services.  To verify your identity, your personal information will be matched with the information contained in your Credit File Report and other third party sources.  You also consent to your personal information being compared to records maintained by third parties, including telecom and other service providers, and you consent to those third parties providing personal information to us and our third-party suppliers for the purpose of identity verification.</p>
<p>By registering to Buckzy and agreeing to our Terms and Conditions,  and submitting your information, you confirm your consent to Securefact collecting, using, disclosing, and storing your personal information for the purpose of this verification.  This is a soft inquiry and will not affect your credit score or be visible to other financial institutions. </p>
<p><strong>RESOLUTION OF DISPUTES:</strong> Except for transactions originating from Quebec, or where otherwise prohibited by applicable law, unless You opt out as set forth below, any dispute arising from or relating to this transaction shall be resolved by final and binding arbitration. The arbitrator shall also decide what is subject to arbitration. The arbitration will be administered by National Arbitration and Mediation (“NAM&#8217;) under its Comprehensive Dispute Resolution Rules and Procedures, which are available at www.namadr.com/downloads.cfm. You will be responsible for up to $125 CAD of the administration fees. Any arbitration shall take place on an individual basis; class actions or arbitrations are not permitted. If any part of this paragraph is deemed invalid, it shall not invalidate the other parts. If NAM is unavailable, the parties or a court will select another arbitrator.</p>
<p><strong>LIMITATIONS OF LIABILITY:</strong> IN NO EVENT SHALL BUCKZY BE LIABLE FOR DAMAGES WHETHER CAUSED BY NEGLIGENCE ON THE PART OF ITS EMPLOYEES, SUPPLIERS, BEYOND THE SUM OF $500 (CAD) (in addition to refunding the transaction amount and the transfer fees), UNLESS YOU HAVE OBTAINED A HIGHER LIABILITY LIMIT BY CALLING THE CUSTOMER SERVICE TELEPHONE NUMBER BELOW AND PAID AN ADDITIONAL FEE THEREFOR. IN NO EVENT SHALL BUCKZY OR ITS PARTNERS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES, OR THE LIKE.</p>
<p>CURRENCY EXCHANGE, INTERNATIONAL SERVICES Payments will generally be in local currency (except that in certain countries payment may be in U.S. dollars or other alternate currency at participating locations). In addition to the Transfer Fees applicable to this transaction, a currency exchange rate will be applied to this transaction. Canadian currency is converted to foreign currency at an exchange rate set by BUCKZY. Any difference between the rate given to You and the rate received by BUCKZY will be kept by BUCKZY in addition to FX spread/margins.  If you choose a payout currency different from the national currency of the country to which you are sending money, the payout currency you choose may not be available for BUCKZY in that country or may not be available in small enough denominations to pay all of your money transfer. In such cases, the paying Buckzy partner BANK  may pay all or part of your transfer in the national currency.</p>
<p><strong>GENERAL:</strong> This Agreement, together with all items incorporated by reference, embodies the entire understanding among the parties. It supersedes all prior understandings and cannot be modified orally. BUCKZY has the full right to assign this Agreement to any party, at any time without Your consent. This Agreement is governed by Ontario law without regard to conflicts of law rules; provided, however, if Your domicile or residence is in Quebec, please note that we are not supporting the transactions from this region processing at this time. Services are directed solely to persons 18 and over (or 19 and over in any jurisdiction where 18 is not the age of majority). The English language version controls if there is an inconsistency between English and non-English Agreement versions. You and Receiver represent that your use of Services does not violate: (a) any law, including, without limit, laws relating to money laundering, illegal gambling activities, support for terrorist activities or fraud; and/or (b) this Agreement. Information provided to Buckzy shall be truthful and complete. You shall indemnify Buckzy and its business partners or agents for all losses of any kind (including lawyers’ fees on a solicitor and client basis) arising out of any Agreement breach by You or Receiver. BUCKZY reserves the right to change Services without notice.</p>
<p><strong>PRIVACY:</strong> Buckzy will collect Personal Information about You and the Receiver from Your Service transactions and, where permitted or required by law, from other sources (&#8220;Personal Information&#8221;), as explained in the Buckzy  Privacy Policies (&#8220;Statement&#8221;).  Buckzy will collect and use Personal Information to: (i) authorize and process Service transactions, (ii) to manage our business, including the detection and prevention of fraud and other illegal activity, (iii) provide customer service and (iv) for other purposes set forth in the Statement. Buckzy may disclose the Information to: (i) the Receiver, (ii) our Agents and contracted third party service providers, (iii) if applicable, any Receiver bank and any intermediate banks or other financial institutions, as may be necessary to process and complete your Service transaction, (iv) law enforcement and other governmental authorities to prevent, detect and investigate fraud or other illegal activity, as permitted or required by law, and (v) as otherwise described in the Statement.</p>

		</main><!-- #main -->
	</section><!-- #primary -->

	
    
				<aside class="addrsSec">
					
					<h4>Contact Us</h4>			<p><strong>CANADA</strong> <br>
Buckzy Payments Inc. 40 University Avenue | Suite 201 
Toronto, Ontario | M5J 1T1</p>
<p><strong>USA</strong><br>
Buckzy Payments Inc. 16192 Coastal Highway Lewes, Delaware | 19958</p>
<p><strong>INDIA </strong><br>
Buckzy Payments Inc. Ground floor Geo Infopark Building 
Kusumagiri P.O | Kakkanad | Cochin</p>
<br>

		
		<section class="fulwidth footerWrap__copyright">
			<div class="page_container">
				<span> Copyright &copy; Buckzy Payments Inc. 2019-2020</span>
			</div>
		</section>

<section class="fulwidth cmmonbannerWrap scrollItm">
	<div class="page_container">
		<aside>
		</aside>
	    <aside>
	        <h1>Privacy Policy</h1>
	    </aside>
	</div>
</section>


	<section id="primary" class="content-area fulwidth commonPageContent">
		<main id="main" class="site-main page_container">

			<h2>Buckzy Payments Inc. Privacy Policy</h2>
<p>Buckzy Payments Inc. (&#8220;Buckzy&#8221;, &#8220;we&#8221;, &#8220;our&#8221;, or &#8220;us&#8221;) is a next generation, online &#8220;instant money transfer&#8221; technology and wallet platform for cross-border money transfers, enabling consumers and businesses (&#8220;Customers&#8221;) to transfer money instantly worldwide. We are committed to providing transparency to our Customers and to protecting the privacy of those who use our service and entrust us with their personal information. For this reason, we have business procedures and security safeguards in place to protect personal information under our control. This Privacy Policy is a formal statement of rights and obligations concerning the minimum requirements for the protection of personal information provided to Buckzy and is intended to establish responsible and transparent practices for the management of personal information and to satisfy the requirements of rules established by data protection and applicable privacy laws and standards.</p>
<h3>Buckzy Payments Inc. Privacy Policy</h3>
<p>
This Privacy Policy generally applies to any personal information (as defined below in the section entitled Definition of Personal information) collected or generated by Buckzy, from you, our Customers and other individuals which whom we deal, including in connection with providing Buckzy’s other online products or services.</p>
<p>This Privacy Policy also governs personal information collected about Buckzy’s website and App users and explains how we use and disclose personal information that we collect from people who visit our website and otherwise interact with us through www.buckzy.net (&#8220;website&#8221;) as well through our mobile app (&#8220;App&#8221;). It also explains how we use cookies and similar technologies.
</p>
<h3>Definition of Personal information</h3>
<p>When used in this Privacy Policy, personal information means &#8220;any information about an identifiable individual&#8221;. This may include, for example, the following:</p>
<ul>
<li>your name, address, email address, date of birth, phone number, gender, nationality, country of birth, marital status, and where authorized by law, your government identification number (including Social Security Number or Social Insurance Number, as applicable), passport,  driver’s license and other information relating to your account with us (including security questions);</li>
<li>credit, debit and/or banking information (including bank account details and bank address), payment and transaction history and other information provided by you directly to us, through affiliates, or through online applications for the transaction funding purposes, including related usernames and account information.</li>
<li>Third party personal information you provide in order for us to facilitate funds transfers, such as your recipient’s full name, address, email address and phone number</li>
</ul>
<p>Personal information may also include other types of more technical information, such as your IP addresses, meta-data (e.g. who our Customers communicated with, when and where this communication occurred), but only when this information can identify you as an individual. Personal information that is de-identified or aggregated and/or that cannot be associated with an identifiable individual is not considered to be personal information.</p>
<h3>Collection and Use of Personal Information</h3>
<p>We collect most of our information about you directly from you or from our Customers, with your or such Customer’s consent. We usually collect your name, address, phone number(s) and email address, as well as other account and banking information, including information pertaining to your use of our products and services when you register to Buckzy services. We may also obtain personal information about you from third parties, with your consent, namely from financial institutions, banks and payment service providers you or any recipient uses to transfer money as well as credit agencies.</p>
<p>We generally collect personal information from or about our Customers and other individuals with whom we deal, and thereafter use it, for the following purposes:</p>
<ul>
<li>to establish, develop and preserve business relationships with our Customers and other individuals with whom we deal</li>
<li>to create, establish and administer our Customers’ accounts and to respond to inquiries and requests;</li>
<li>to provide, and assist us in providing and improving the provision of, the products or services that we offer and ensure that all related transactions are supported and implemented;</li>
<li>to verify and authenticate the identity of our Customers and other individuals with whom we deal, and preserve their privacy when they contact us by telephone, electronic means or otherwise;</li>
<li>for internal training, quality assurance, data analysis, statistical and research purposes and to measure the effectiveness of advertising we serve;</li>
<li>to manage our business and ensure the efficiency, reliability and security of our systems and networks;</li>
<li>as necessary, to establish or verify the credit of persons that use, or intend to use, one or more of the products or services offered by Buckzy;</li>
<li>to meet legal and regulatory requirements and allow Buckzy to meet contractual requirements relating to the products and services provided to Customers;</li>
<li>to detect and prevent fraud and other illegal uses of our product and services and to comply with &#8220;know-your-customer&#8221; terrorist financing and anti-money laundering requirements;</li>
<li>to ensure our records are accurate and to protect our Customers and Buckzy from error and fraud;</li>
<li>to provide personalized products and services as well as product recommendations to our Customers, to enhance your website and App experience and to deliver information specific to your interests on additional websites; and</li>
<li>subject to our Customers’ Right to Withdraw Consent provided in this Privacy Policy, to conduct surveys on the quality of our products, services or customer service or to provide our Customers with offers for additional products and services that we feel may be of interest to our Customers or that we believe meet our Customers’ changing needs.</li>
</ul>
<p>If you have applied for employment with Buckzy, the personal information submitted with your job application will be used for recruitment and other customary human resources purposes. For example, we may send you information about new job opportunities within Buckzy as well as other career development resources.</p>
<p>Unless required or authorized by law, Buckzy will not collect or use personal information for any other or new purpose without obtaining further consent. For details on the type of information that we collect via our website and App and on how we use and share this information, please refer to the Website and Mobile App Privacy section of this Privacy Policy.</p>
<h3>Your Consent</h3>
<p>Buckzy obtains consent from Customers or individuals concerned for the collection, use or disclosure of personal information through various means: orally or through written documents, including agreements or forms (electronic or otherwise), welcome kits as well as printed or electronic communications. The form of consent sought by Buckzy will vary according to the sensitivity of the personal information, the reasonable expectations of the individuals and any requirements or standards established by law.</p>
<p>In general, by using Buckzy products and services and/or submitting information to us in connection with using Buckzy products and services, you are providing your consent to the collection, use and disclosure of personal information as set out in this Privacy Policy and in the Buckzy Terms and Conditions. Moreover, by visiting, interacting with and/or using our website or App, you agree to benefit from, be bound by, the terms and conditions of this Privacy Policy.</p>
<h3>Right to Withdraw Consent</h3>
<p>Consent may be withdrawn at any time, subject to legal or contractual restrictions and reasonable notice; however, without such consent, Buckzy may limit the services or products it is able to provide to Customers. You may also withdraw your consent as follows:</p>
<ul>
<li>Mail marketing, Telephone marketing and Quality control communications. You may decide that you prefer Buckzy not to use your personal information to promote new and/or additional products and/or services which may be of interest to you and refuse that we contact you by mail or telephone for marketing purposes or by email or telephone for quality control purposes. If this is the case, you may advise us by contacting customer service or contacting us using the information detailed in the How to Contact Us section below.</li>
<li>Commercial Electronic Communications. You can always limit the communications that Buckzy sends to you. It is Buckzy policy, however, not to disclose your personal information or contact details to third parties to permit those organizations from directly offering and providing information about their products and services. To opt-out of commercial emails, simply click the link labeled &#8220;unsubscribe&#8221; or &#8220;opt-out&#8221; at the bottom of any commercial electronic communication we send you. Please note that even if you opt-out of promotional communications, we may still need to contact you with important information about your account. If you have any questions about your choices or if you need any assistance with opting-out, please contact us using the information detailed in the How to Contact Us section below.</li>
</ul>
<p>For information on how you can limit the type of information that we collect, use or share about you when you visit and/or use our website, please refer to the Website and Mobile App Privacy section of this Privacy Policy.</p>
<h3>Sharing of Personal Information</h3>
<p>Your information may be made available by Buckzy to third-parties or service providers to fulfill the purposes for which it has been collected or as set forth in this Privacy Policy. Buckzy will NOT sell, rent or trade your personal information to any third party. However, we may share your personal information when authorized by law or as follows:</p>
<ul>
<li>Third party or affiliated service providers. We may hire service providers, which may be affiliates or third parties, to perform services on our behalf. This may include, without limitation, a person or an organization retained by Buckzy to perform work on its behalf, including for the processing of Customer transactions, for Customer credit verification, to prevent fraud, theft or loss or to provide marketing, information technology and/or data hosting or processing services. We provide them with a limited amount of information which is necessary in order for them to provide the services required. They are prohibited from using the information for purposes other than to facilitate and carry out the services they have been engaged to provide.</li>
<li>As permitted or required by law. From time to time, Buckzy may be compelled to disclose personal information in response to a law, regulation, court order, subpoena, valid demand, search warrant, government investigation or other legally valid request or enquiry, including for purposes such as detecting, investigating, prosecuting and preventing crimes, including money laundering and related criminal activity.  We may also disclose information to our accountants, auditors, agents and lawyers in connection with the enforcement or protection of our legal rights. We also reserve the right to report to law enforcement agencies any activities that we, in good faith, believe to be unlawful or to law enforcement and emergency services providers, in an emergency or where required or permitted by law. We may release certain personal information when we have reasonable grounds to believe that such release is reasonably necessary to protect the rights, property and safety of others and ourselves, in accordance with or as authorized by law.</li>
<li>Business transaction. We may disclose personal information to a third party in connection with a sale or transfer of business or assets, an amalgamation, re-organization or financing of parts of our business. However, in the event the transaction is completed, your personal information will remain protected by applicable privacy laws. In the event the transaction is not completed, we will require the other party not to use or disclose your personal information in any manner whatsoever and to completely delete such information.</li>
<li>Other types of sharing. We may provide your information to banks or other financial institutions or financial services organizations involved in the transactions or our services (including those of the recipient), as well as credit bureaus, credit grantors and reporting agencies, subject to applicable law. We may also disclose our Customers’ personal information to an individual who, in the reasonable judgement of Buckzy, is acting on behalf of the individual in question, such as through a power of attorney.</li>
</ul>
<p>Only those employees of Buckzy or trusted third party contractors, who need access for business reasons, or whose duties reasonably so require, will be granted access to personal information about you or Customers.</p>
<p>For information on how you can limit the type of information that we collect, use or share about you when you visit and/or use our website or App, please refer to the Website and Mobile App Privacy section of this Privacy Policy.</p>
<h3>Website and Mobile App Privacy</h3>
<p>Buckzy generally collects and uses personal information from or about its website visitors and users as well as users of its App, as follows:</p>
<ul>
<li>Information you provide. In many cases, we collect personal information directly from you when you visit or use our website or App. For instance, we may collect the following types of information:</li>
<li>Inquiries. We may collect your name, contact information, e-mail address and any information you provide us when you make an inquiry or contact us through our website or App. We will only use this information to provide you with the information requested or to contact you in order to answer your questions and/or requests.</li>
<li>Requests for a Product and/or Service. We may collect your name, contact information, email address and any other information provided to us when you sign up to receive our newsletter or when you participate in a contest or promotion run by Buckzy or when you submit a request or an order for a Buckzy product or service. We will only use this information to process and answer your request or to manage our everyday business needs in connection with such participation or request.</li>
<li>My Account. We may collect your information when you log-in on our website through My Account and interact with us through your account. We will only use the information that you provide us in accordance with this Policy.</li>
<li>Satisfaction Survey. When you visit our website, you may, from time to time, be invited to participate to a satisfaction survey. In such case and if you decide to participate to our survey, we may collect your postal code, email address and other information deemed important to complete such survey. We will only use your information for quality assurance purposes.
<ul>
<li>Technical information. When you visit our website, we may collect, using electronic means such as cookies, technical information. This information may include information about your visit to our website, including the IP address of your computer and which browser you used to view our website, your operating system, resolution of screen, location, language settings in browsers, the site you came from, keywords searched (if arriving from a search engine), the number of page views, information you entered, advertisements you have seen, etc. This data is used to measure and improve the effectiveness of our website or enhance the experience for our Customers by allowing you to store your preferences and account information. For instance, it may be used to ensure that the products advertised on our website are available in your area or that we are not showing you the same ads repeatedly. We treat this information as personal information when it is associated with your account or contact information. For instance, we may collect and use the following type of information when you visit and/or interact with us on our websites:</li>
</ul>
</li>
<li>Google Analytics: We use Google Analytics which allows us to see information on user website activities including, but not limited to, page views, source and time spent on our website. This information is depersonalized and is displayed as numbers, meaning that it cannot be tracked back to individuals. You may opt-out of our use of Google Analytics by visiting the Google Analytics opt-out page.</li>
<li>Google AdWords: We use Google AdWords Remarketing to advertise Buckzy across the Internet and to advertise on third party websites (including Google) to previous visitors to our websites. AdWords remarketing will display ads to you based on what parts of the Buckzy website you have viewed by placing a cookie on your web browser. It could mean that we advertise to previous visitors who haven’t completed a task on our site or this could be in the form of an advertisement on the Google search results page, or a site in the Google Display Network. This cookie does not in any way identify you or give access to your computer or mobile device. The cookie is only used to indicate to other websites that you have visited a particular page on our website, so that they may show you ads relating to that page. If you do not wish to participate in our Google AdWords Remarketing, you can opt out by visiting Google’s Ads Preferences Manager.
<p>Interest-based Advertising. Buckzy may also allow a limited number of trusted third parties to install cookies on your hard drive from our websites. Our websites will not include any third-party advertising or links to other websites which may be used to generate personalized advertisements. We do not provide any personal information to advertisers or to third party sites and no third-party or interest-based ads will appear on our websites. However, we use third party services to manage our advertising on other sites. Such third parties may use technologies such as cookies to gather information about your activities on the website and other sites in order to provide you with personalized advertisements. We do not have access to or control over cookies or other features that advertisers and third party sites may use, and the information practices of these advertisers and third party websites are not covered by our Privacy Policy. Please contact them directly for more information about their privacy practices. We may make use of cookies or other technologies such as pixels or web beacons in order to display our ads on third party websites or application (mobile or otherwise) and to deliver information specific to your interests via email, through our websites or apps, and on third party websites and apps. You can generally opt-out of receiving personalized ads from third party advertisers and ad networks who are members of the Digital Advertising Alliance of Canada. Please visit the DAAC opt-out page for more information.</li>
<li>Social media. This information will be collected primarily from you as information voluntarily provided to us through our website, App, social media channels, but we may also collect it from you where we communicate with you by telephone, post, email or other means of communication. Where lawful to do so we may also obtain it (or combine it with information from) public sources, third party service providers, individuals who have indicated you have agreed for them to provide your personal information, government, tax or law enforcement agencies and other third parties. For example, if you are on another website and you opt-in to receive information from Buckzy, that website may submit to us your email address and other information about you so that we may contact you as requested. You may also choose to participate in a third party application or feature (such as one of our Facebook or Twitter applications or a similar application or feature on a third party website) through which you allow us to collect (or the third party to share) information about you, such as details of your friends/connections, &#8220;likes&#8221;, comments you have shared, groups and location. In addition, we may receive information about you if other users of a third party website give us access to their profiles and you are one of their friends/connections or information about you is otherwise accessible through your friends’/connections´ web page, profile page, or similar page on a social media channel or other third party website or interactive service. We may supplement the information we collect about you directly with the information we receive from third parties in order to enhance our ability to serve you, to tailor our content to you and send you details of promotions and/or offers which we believe may be of interest to you.
<ul>
<li>Your Choice with Cookies. You can block the use of cookies by activating the settings in your browser. The &#8220;Help&#8221; feature on most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. If you choose to withhold consent, or subsequently block cookies, you may not be able to access all or part of the content of our website. Additionally, you can disable or delete similar data used by browser add-ons, by changing the add-on’s settings or visiting the website of its manufacturer.</li>
<li>Privacy Policies of Third Parties. This Privacy Policy only addresses the use and disclosure of information by Buckzy. Other websites that may be accessible through Buckzy websites, such as social media websites, have their own privacy policies and data collection, use and disclosure practices. We encourage you to familiarize yourself with the privacy statements provided by all third parties prior to providing them with information or taking advantage of an offer or promotion.</li>
<li>Mobile Apps. In the event that you download and/or use our App, we may require and collect, directly from you, information necessary to authenticate you.</li>
</ul>
</li>
</ul>
<h3>Personal Information Outside of Canada</h3>
<p>In some cases, personal information that we manage may be transferred, processed and stored outside Canada, and therefore may be available to government authorities under lawful orders and laws applicable there. Buckzy will strive to protect personal information disclosed to third parties by contractual agreements requiring that those third parties adhere to confidentiality and security procedures and protections. If you are a corporate Customer, you accept and undertake to inform your own customers that the personal information that we collect from you may be transferred, processed and stored outside Canada.</p>
<h3>Security of Personal Information</h3>
<p>We will use reasonable security measures to protect your personal information against unauthorized access. Buckzy has implemented security measures that contain administrative, technical and physical controls that are designed to safeguard your personal information from loss, theft, unauthorized access, use or disclosure. For example, we use industry-standard encryption technology to secure sensitive personal information when it is being collected and transmitted over the Internet as well as firewalls, site monitoring and intrusion detection software.</p>
<h3> Minors </h3>
<p>Our website and App are not directed at children under the age of 13. Buckzy does not knowingly collect or maintain personal information at our website or App from those we know are under the age of 13.</p>
<h3> Retention </h3>
<p>Personal information will be retained only as long as necessary for the fulfilment of the purposes for which it was collected and for which consent was received, unless otherwise required by law. Personal information that is no longer required to fulfil the identified purposes will be destroyed, erased or made anonymous. Buckzy has developed and implemented detailed retention principles, and will ensure destruction of personal information in a method that prevents improper access.</p>
<h3>Access and Correction</h3>
<p>Buckzy respects your right to access and correct your personal information. If you have an online account with us, you can log into My Account at any time to access and update the information you have provided to us. Additionally, Buckzy complies with all laws regarding access and correction. If you need assistance updating your personal information, please contact us using the contact information detailed in the How to Contact Us section below. In certain circumstances, we may direct your requests for access or correction of your personal information to our corporate Customers, as applicable.</p>
<h3>How to Contact Us</h3>
<p>If you have questions, concerns or complaints regarding this Privacy Policy or the personal information that Buckzy has about you, please contact:</p>
<p>
<b>Buckzy Payments Inc.</b></p>
<p>Buckzy Payments Inc. 40 University Avenue | Suite 201</p>
<p>Toronto, Ontario M5J 1T1</p>
<p>By e-mail: <a href="mailto:support@buckzy.net">support@buckzy.net</a>
</p>
<h3>Change of Privacy Policy</h3>
<p>Buckzy will review and update its policies and procedures as required to keep current with rules and regulations, new technologies, standards and customer concerns. Our Privacy Policy may therefore change from time to time. We will post any privacy policy changes on our website and, if the changes are significant, we will provide a more prominent notice.</p>
<p>Please note that this version does not substantively change the way we treat personal information compared to the previous version of the Privacy Policy.</p>
<p>This Privacy Policy was last updated on 2018.</p>
<p>Please note that both the English and French versions of our Privacy Policy have equal legal value.</p>
<p><b>MTL01: 4605062: v5</b></p>

		</main><!-- #main -->
	</section><!-- #primary -->

</aside>

