import { Component } from '@angular/core';
import { environment } from 'environments/environment';
// import { environment } from 'environments/environment.prod';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
       <b><a style="text-decoration:none" href="https://buckzy.net" target="_blank">@ Buckzy</a></b>. All Rights Reserved
       <br>
       <span>{{currentApplicationVersion}}</span>
    </span>
    
    <div class="socials">
      <!-- <a href="#" target="_blank" class="ion ion-social-github"></a> -->
      <a href="https://www.facebook.com/BuckzyPaymentsInc" target="_blank" class="ion ion-social-facebook"></a>
      <a href="https://twitter.com/BuckzyPayments" target="_blank" class="ion ion-social-twitter"></a>
      <a href="https://linkedin.com/company/buckzypayments" target="_blank" class="ion ion-social-linkedin"></a>
    </div>
  `,
})
export class FooterComponent {
  currentApplicationVersion = environment.appVersion;
}
