import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment } from '../../../environments/environment';
import { map, take } from 'rxjs/operators';
import { ClientAccountsInfo } from 'app/models/clients/account/account.model';
import { PaginatedAccountResponse } from 'app/models/clients/account/paginated-account-response.model';
import { ApiGetAccountParams } from 'app/models/clients/account/api-get-account-params.model';

const ACCOUNTS_URI = '/v1/accounts';
const CLIENT_STATEMENT_URI = '/v1/client-statements';
const WALLET_BALANCE_URI = '/wallet-balances';
const CLIENT_INFO_URI = '/client-information';
const ACCOUNT_SUMMARY_URI = '/client-account-info';
const CLIENT_STATEMENT_DETAIL_URI = '/account-statements';
const FUND_ACCOUNT_URI = '/fund-accounts';

const defaultToDate = new Date();
const defaultFromDate = new Date();

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }

  /**
   * 
   * @returns Observable<HttpResponse<any>> 
   */

  setAccountNickName(accountId:number, nickName: string): Observable<HttpResponse<any>> {
    const h = {
      'Content-Type' : 'application/json',
      'access-control-expose-headers' : '*',
    };
    const URL = environment.BASE_URL + ACCOUNTS_URI;
    return this.http.put<any>( URL + '/' + accountId, {nickName}, { headers : h, observe: 'response'} );
  }


  getFundingAccounts(accountId: number): Observable<Array<ClientAccountsInfo>> {
    const URL = `${environment.BASE_URL}${ACCOUNTS_URI}/${accountId}${FUND_ACCOUNT_URI}`
    return this.http.get<Array<ClientAccountsInfo>>(URL);
  }

  formatTextAccountData(account: ClientAccountsInfo): string {
    return `${account?.currencyCode} ( **** ${account?.financialInstitutionDetails?.accountNumber?.toString().slice(-4)} ) \n ${account?.financialInstitutionDetails?.accountName}`;
  }
}
