let SUPERSET_USERNAME = 'admin';
let SUPERSET_SECRET = '12dfErf4^sd*dS3]F4Gs*dT_oP43z';

export const environment = {
  appVersion: require('../../package.json').version + '-uat',
  production: false,

  BASE_URL: 'https://api-sb.buckzy.net',

  SUPERSET_URL:
    'http://34.70.176.100:8088/login?username=' +
    SUPERSET_USERNAME +
    '&secret=' +
    SUPERSET_SECRET +
    '&redirect_url=',

  RE_CAPTCHA_SITE_KEY: '6Le3wvUUAAAAAOGI2zEPMmnv4jOi6fXZIZ9UgL-T',

  AUTH: {
    API_KEY: 'dKbSngIQqwrrvYKnjFkVfkQSoKJJbALk',
    AUTH_BASE: 'https://auth-sb.buckzy.net',
    AUTH_URL: '/oauth/token',

    CLIENT_ID: 'TEST_MTO',
    CLIENT_PWD: 'TEST_MTO',

    TYPE: 'BD',

    CLIENTS_LIST: [
      {
        CLIENT_ID: 'SBOX_BUCKZYTEST',
        CLIENT_PWD: 'SBOXBUCKZYTEST',
      },
      {
        CLIENT_ID: 'DEV_BUSINESS_MTO',
        CLIENT_PWD: 'DEV_BUSINESS_MTO',
      },
      {
        CLIENT_ID: 'DEV_BERLIN',
        CLIENT_PWD: 'DEV_BERLIN',
      },
      {
        CLIENT_ID: 'TEST_BUSINESS_MTO',
        CLIENT_PWD: 'TEST_BUSINESS_MTO',
      },
      {
        CLIENT_ID: 'SBOX_GURUPAY',
        CLIENT_PWD: 'SBOX_GURUPAY',
      },
      {
        CLIENT_ID: 'TEST_MTO_1',
        CLIENT_PWD: 'TEST_MTO',
      },
      {
        CLIENT_ID: 'DEMO_CLIENT',
        CLIENT_PWD: 'DEMO_CLIENT',
      },
      {
        CLIENT_ID: 'CROSSRIVER_TESTCLIENT02',
        CLIENT_PWD: 'testclient@123#',
      },
      {
        CLIENT_ID: 'SBOX_ADRIATICBANK',
        CLIENT_PWD: 'w6XLheL9jCt7jdESUl',
      },
      {
        CLIENT_ID: 'SBOX_PAYSYS',
        CLIENT_PWD: 'SBOX_PAYSYS',
      },

      {
        CLIENT_ID: 'SBOX_DEMO',
        CLIENT_PWD: 'h7BuZWBbkHIbVbEtUMQN0K4',
        NUVEI_MERCHANT_CONFIG : {
          env: 'int',
          merchantId: '1522461514352937073',
          merchantSiteId: '243328'
        },
      },
      {
        CLIENT_ID: 'SBOX_DEMO2',
        CLIENT_PWD: 'h7BuZWBbkHIbVbEtUMQN0K4',
      },
      {
        CLIENT_ID: 'SBOX_SWIFTWAZE',
        CLIENT_PWD: 'ntcqTZPPYb2LUZR9RPdDCHbgBMHL',
      },
      {
        CLIENT_ID: 'SBOX_PREMIERFOREX',
        CLIENT_PWD: 'v3s3HEfVKBtHWDjVDJP8kprFHjLf',
      },
      {
        CLIENT_ID: 'SBOX_EGATES',
        CLIENT_PWD: 'OgfI9qervMjcokAGRZ8ixvNvATSaGjXs',
      },
      {
        CLIENT_ID: 'SBOX_NEXG',
        CLIENT_PWD: 'SBOXNEXG',
      },
      {
        CLIENT_ID: 'SBOX_BYTEX',
        CLIENT_PWD: 'SBOXBYTEX',
      },
      {
        CLIENT_ID: 'SBOX_BONANZA',
        CLIENT_PWD: 'rixEdraXozi9=k52hoze',
      },
      {
        CLIENT_ID: 'SBOX_AVIS',
        CLIENT_PWD: 'dosWlSpLcR0S0*rUQIco',
      },
      {
        CLIENT_ID: 'SBOX_PAY2SEA',
        CLIENT_PWD: 'SMBnzG:xBYQrpB8MM&Vz',
      },
      {
        CLIENT_ID: 'SBOX_ALIANZA',
        CLIENT_PWD: 'zN9rhEuyuXvVnfdMgRXz',
      },
      {
        CLIENT_ID: 'SBOX_NEXTERPAY',
        CLIENT_PWD: 'tcqgvAUTRemTDUK99M',
      },
      {
        CLIENT_ID: 'SBOX_XPVENTURELABS',
        CLIENT_PWD: 'MWVq7eEeAbBMBKgVdLzG',
      },
      {
        CLIENT_ID: 'SBOX_SPHERE',
        CLIENT_PWD: 'HcCLEpKJwYhrMdSVtE27!',
      },
      {
        CLIENT_ID: 'SBOX_ONEWORLDBANCORP',
        CLIENT_PWD: 'dltH*!ucu3r2Qos?l6ro',
      },
       {
        CLIENT_ID: 'SBOX_PAYGAMES2',
        CLIENT_PWD: 'vXh_mKRdDZGL6KaqLAshya',
      },
      {
        CLIENT_ID: 'SBOX_RAZORMINT',
        CLIENT_PWD: 'vRazYCjZa!WZYNTareXY2',
      },
      {
        CLIENT_ID: 'SBOX_DELTEC',
        CLIENT_PWD: 'WsaaLxJ7XzfLcL@Kn2vwh2G!',
      },
      {
        CLIENT_ID: 'SBOX_CONNECTPLUS',
        CLIENT_PWD: 'mwpKHuQ5r@GybazhddzzRR!KQJa',
      },
      {
        CLIENT_ID: 'SBOX_VISTACAPITAL',
        CLIENT_PWD: 'U9!jGqEN@g@ztYWhSDpLzmC7T',
      },
      {
        CLIENT_ID: 'SBOXNEXG',
        CLIENT_PWD: 'swtqSx2vwXDW@SjKWT9LbxAfYK',
      },
      {
        CLIENT_ID: 'SBOX2_ABLEEXPRESS',
        CLIENT_PWD: 'OLsqwYMPGJJSU$%',
      },
      {
        CLIENT_ID: 'SBOX_OPENPAYD',
        CLIENT_PWD: 'sTjCkMANqBqd_sryC7FvJ#',
      },
      {
        CLIENT_ID: 'SBOX_XAZUR',
        CLIENT_PWD: 'ShF_3s5fdXVxzAgKe5a?rm',
      },
      {
        CLIENT_ID: 'BANCO_ARBI',
        CLIENT_PWD: 'bdAzXrd@X4tSZbKbba4D!HeGhg',
      },
    ],
  },

  PAY_LATER: {
    ENABLED: false,
  },
  ACH: {
    ENABLED: true,
    MAX: 100000,
  },

  PLAID: {
    ENABLED: false,
    BASE_URL: 'https://plaid-sb.buckzy.net/PlaidService',
    OPTIONS: {
      clientName: 'Plaid Quickstart',
      language: 'en',
      env: 'sandbox',
      selectAccount: true,
      key: 'd675c8e77a29deb226beba95a7c92d',
      product: ['transactions'],
      // Optional – use webhooks to get transaction and error updates
      webhook: 'https://requestb.in',
    },
  },
};
