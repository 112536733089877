import { AfterContentChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';
import { BUCKZY_CONSTANTS } from 'app/models/buckzy-constants/buckzy-constants.model';
import { BuckzyCode } from 'app/models/handle-party/buckzy-code.model';

import { AccountService } from 'app/services/finance/account.service';
import { GeneralService } from 'app/services/mto/general/general.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'window',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.scss'],
})

export class WindowComponent implements OnInit, AfterContentChecked {

  fileName: any = [];
  file: File;
  fileType: string;
  fileTypes: Observable<BuckzyCode[]>;
  @ViewChild('dragDropContainer') dragDropContainer: ElementRef;
    loading = false;
    showAlert = false;
    response: any = {};
    alertStatus = '';
    showScreen: boolean;
    rowNumber: number;
    rows = [
      {
        value: 1
      },
      {
        value: 5
      },
      {
        value: 10
      }
    ];
    showSummary: boolean = false;
    amt = null;
    self = {
      windowRef: null
    };
    showDeals: boolean = false;
    columns = ['ID',  'Submitted At', 'Type', 'Name', 'Submitted By', ''];
    attachmentCoumns = ['Date', 'File Name', 'Uploaded By', ''];
    reportDownloadColumns = ['ID',  'File Name', 'Date & Time', 'Report Type', 'Format', 'Status', 'Action'];
    reportDownloadResponse: any;
    transactions = [];
    title: string = null;
    showAttachments: boolean = false;
    editModeForNickName = false;
    comments: string = null;
    screenName: string = null;
    showReportDownload: boolean = false;
    disabledUpload: boolean;

    canSelectDocumentType: boolean = false;

    constructor(private windowRef: NbWindowRef, private accountService: AccountService, private generalService: GeneralService) {
      this.initializeDocumentTypeCodes();
      var responseData, action;
      this.self.windowRef = windowRef;
      responseData = windowRef.config.context;
      action = windowRef.config.title;

      try {
        this.response = responseData.text;
      } catch(e) {}

      try {
        this.screenName = responseData.screen;
      } catch(e) {}

      if(responseData?.data) {
        this.response = responseData.data
      }

      if(this.response == undefined) {
        this.response = windowRef.config.context;
      }

      this.amt = this.response?.data?.amount;

      if(windowRef.config.title == 'Select Deal')
        this.showDeals = true;
      else
        this.showDeals = false;

      if(windowRef.config.title == 'View Attachments')
        this.showAttachments = true;
      else
        this.showAttachments = false;

      if(this.response == 'Mass Pay')
        this.showScreen = true;
      else
        this.showScreen = false;
      
      try {
        this.title = windowRef.config.title;
        if(windowRef.config.context == BUCKZY_CONSTANTS.PAGES.PARTY_DETAILS) {
          this.canSelectDocumentType = true;
          this.disabledUpload = true;
        }
        this.amt = this.response.data.amount;
      } catch(e) {}

      try {
        if(action == 'Download CSV Report' || action == 'Download PDF Report') {
          this.showReportDownload = true;
          this.reportDownloadResponse = responseData.content;
        }
        else
          this.showReportDownload = false;

      } catch(e) {}

    }
  
    editNickNameToggle() {
      if(this.editModeForNickName) {
        this.accountService.setAccountNickName(this.response.accountId, this.response.nickName).subscribe(data=>{
        }, err => {console.log(err);});
      }
      this.editModeForNickName = !this.editModeForNickName;
    }

    ngAfterContentChecked() {
      var nb_window;
      nb_window = document.querySelector('nb-window');
      nb_window.style = "width: 70%";
    }

    ngOnInit() { }

    copyToClipboard() {
      // this.loading = true;
      var myInput, myLabel, message = '';
      myInput = document.getElementsByClassName("myInput");
      myLabel = document.getElementsByClassName("myLabel");
      // idText.select();
      for(var i = 0; i < myLabel.length; i++) {
        if(myInput[i].value != '') {
          if(message == '')
            message = myLabel[i].innerText + ": " + myInput[i].value;
          else
            message = message + ", " + myLabel[i].innerText + ": " + myInput[i].value;
        }
        else {
          if(message == '')
            message = myLabel[i].innerText;
          else
            message = message + ", " + myLabel[i].innerText;
        }
      }
      this.copyText(message);
    }

    copyText(val: string){
      let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      
      this.loading = false;
    }

  showData() {
    if(!this.showSummary)
      this.showSummary = true;
  }

  getAmount(amount?: any, comments?: string) {
    if(amount == undefined) {amount = "no amount"}
    this.self.windowRef = this.self.windowRef ? this.self.windowRef : {};
    
    this.self.windowRef.amount = amount;
    this.self.windowRef.comments = comments;
    this.self.windowRef.currency = this.response.data.currency;
    this.self.windowRef.orderId = this.response.data.orderId;
    this.self.windowRef.status = "amount entered";
    this.self.windowRef.windowName = this.screenName;
    this.closeWindow();
  }

  closeWindow() {
    this.self.windowRef.close();
  }

  selectFxDeal(transaction) {
    this.self.windowRef.transaction = transaction;
    this.closeWindow();
  }

  downloadFile(file) {
    this.self.windowRef.documentId = file.transactionDocumentId || file.id;
    this.self.windowRef.documentName = file.fileName;
    this.self.windowRef.windowName = "download_file";
    this.closeWindow();
  }

  uploadFile(event) {
    let fileEvent: File[];
    // if(!this.disabledUpload) {
      if (event.target){
        fileEvent = event.target.files;
      } else {
        fileEvent = event;
      }
      for (let index = 0; index < fileEvent.length; index++) {
        const element = fileEvent[index];
        //if (this.files.length > 0) { this.files = []; } // There can only be one!
        this.fileName.push(element.name);
        this.file = fileEvent[0];
      }

  }

  deleteAttachment(index, screen?: any) {
    this.closeWindow();
  }

  sendFile() {
    this.self.windowRef.file = this.file;
    this.self.windowRef.windowName = "upload_file";
    this.closeWindow();
  }

  initializeDocumentTypeCodes() {
    this.fileTypes = this.generalService.getBuckzyCodes('DOCUMENT_TYPE');
  }

  handleFileType(fileType: BuckzyCode) {
    this.self.windowRef.fileType = fileType;
    if(this.fileType) {
      this.disabledUpload = false;
    }
  }
}

