import { Injectable } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { NbCalendarRange, NbComponentStatus, NbDialogService, NbGlobalPosition, NbIconConfig, NbToastrService, NbWindowRef, NbWindowService } from '@nebular/theme';
import { Router } from '@angular/router';
import { AlertBoxComponent } from '../../pages/secure/common-modules/alert-box/alert-box.component';
import { WindowComponent } from '../../pages/secure/common-modules/window/window.component';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  private language: any;
  dialogsRef: any;
  windowRef: NbWindowRef;
  csvReport = {
    content: [
     {
       id: "uuid123456abcd7890",
       type: "Transaction History",
       format: "CSV",
       createdOn: "2021-08-21 14:05:05",
       url: "http://reports.buckzy.net/storage/{{randomHash}}/BATCH_2021_08_21_uuid123456abcd7890.csv",
       status: "generated",
       name: "FX_Deals_12sdf3df34gfd43s.csv"
     },
     {
       id: "uuid99999abcd00000",
       type: "Transaction History",
       format: "CSV",
       createdOn: "2021-08-22 16:15:35",
       url: null,
       status: "pending",
       name: "FX_Deals_12sdf3df34gfd43s.csv"
     }
    ]
   };
   pdfReport = {
    content: [
     {
       id: "uuid123456abcd7890",
       type: "Transaction History",
       format: "PDF",
       createdOn: "2021-08-21 14:05:05",
       url: "http://reports.buckzy.net/storage/{{randomHash}}/BATCH_2021_08_21_uuid123456abcd7890.pdf",
       status: "generated",
       name: "FX_Deals_12sdf3df34gfd43s.pdf"
     },
     {
       id: "uuid99999abcd00000",
       type: "Transaction History",
       format: "PDF",
       createdOn: "2021-08-22 16:15:35",
       url: null,
       status: "pending",
       name: "FX_Deals_12sdf3df34gfd43s.pdf"
     }
    ]
   }

  constructor(public toastrService: NbToastrService, public alertController: NbDialogService, private auth: NbAuthService, public router: Router, public dialogService: NbDialogService, private windowService: NbWindowService) { }

  getErrorText(err: any) {    
    let errorText = "";
    if (typeof(err)=='string') {
      errorText = err ;
    } else if (typeof(err)=='object') {
      if (err.error?.errors) {
        if (err.error.errors?.length>0) {
          err.error.errors.forEach(er => {
            errorText += ( "\n\n " + er.message + " " )
          });
        }
        else if(err.error?.errors?.request) {
          if (err.error.errors.request[0] && err.error.errors.request[0].message) {
            // This is the actual text that we always want to return! 
            // All test above are getting backup values to return in rare cases when err comes back as a non standard object 
            errorText = err.error.errors.request[0].message;
          }
        }
      } else if (err.error && err.error?.title) {
        errorText = err.error.title;
      } else if (typeof(err.error?.error)=='string' ) {
        errorText = err.error.error;
      } else if (typeof(err.error)=='string' ) {
        errorText = err.error;
      }
    } 
    return errorText;
  }
  showAlert(title: string, messageTxt: string) {
    alert(messageTxt);
  }

  getErrorMessages(errMsg: any) {
    return this.getErrorText(typeof errMsg === 'function' ? errMsg() : errMsg)
  }

  removeElementFromStringArray(element: string, stringArray: string[]): string[] { 
    var newStringArray: string[] = Array.from(stringArray);

    newStringArray.forEach((value,index) => {
        if(value == element) {
          newStringArray.splice(index,1);
        }
    });

    return newStringArray;
  }

 getFormattedDate(datetime, format) {
    var day, month, date, finalDate = null;

    try {
      datetime = datetime.replace(/-/g, '/');
      date = new Date(datetime);
    } catch(e) {
      date = datetime?datetime:new Date();
    }
    

    if(date.getDate().toString().length == 1)
      day = "0" + date.getDate();
    else
      day = date.getDate();
      
    if((date.getMonth() + 1).toString().length == 1)
      month = "0" + (date.getMonth() + 1);
    else
      month = (date.getMonth() + 1);

    if(format == "yyyy/MM/dd")
      return date.getFullYear() + "/" + month + "/" + day;
    else if(format == "yyyy-MM-dd" || format == "yyyy-mm-dd")
      return date.getFullYear() + "-" + month + "-" + day;
    else
      return month + "/" + day + "/" + date.getFullYear();
  }

 getFormattedDateTime(datetime) {
    var day, month, hours, mins, suffix;

    var date = new Date(datetime);

    if(date.getDate().toString().length == 1)
      day = "0" + date.getDate();
    else
      day = date.getDate();
      
    if((date.getMonth() + 1).toString().length == 1)
      month = "0" + (date.getMonth() + 1);
    else
      month = (date.getMonth() + 1);
          
    if(date.getHours() > 12) {
      hours = date.getHours() - 12;
      suffix = "PM";
      if(hours.toString().length == 1)
        hours = "0" + hours;
    }
    else {
      suffix = "AM";
      hours = date.getHours();
      if(hours.toString().length == 1)
        hours = "0" + hours;
    }

    if(date.getMinutes().toString().length == 1)
      mins = "0" + date.getMinutes();
    else
      mins = date.getMinutes();

    return month + "/" + day + "/" + date.getFullYear() + " " + hours + ":" + mins + " " + suffix;
  }

  getFromTimeZonedDate(date) {
    var day, month, hours, mins, sec, milSec, timeZone;

    if(date.getDate().toString().length == 1)
      day = "0" + date.getDate();
    else
      day = date.getDate();

    if((date.getMonth() + 1).toString().length == 1)
      month = "0" + (date.getMonth() + 1);
    else
      month = (date.getMonth() + 1);

    if(date.getHours().toString().length == 1) 
      hours = "0" + date.getHours();
    else
      hours = date.getHours();

    if(date.getMinutes().toString().length == 1)
      mins = "0" + date.getMinutes();
    else
      mins = date.getMinutes();

    if(date.getSeconds().toString().length == 1)
      sec = "0" + date.getSeconds();
    else
      sec = date.getSeconds();

    if(date.getMilliseconds().toString().length == 1)
      milSec = "00" + date.getMilliseconds();
    else
      milSec = date.getMilliseconds();

      date

    var timezeoneOffset = date.getTimezoneOffset() / 60;
    if(date.getTimezoneOffset() >= 0) {
      if(timezeoneOffset.toString().length == 1) 
        timeZone = "-0" + timezeoneOffset + ":00";
      else {
        var number = timezeoneOffset.toString().split(".");
        var temp = "0." + number[1];
        timeZone = "-0" + parseInt("" + number[0]) + ":" + parseFloat(temp) * 60;
      }
    }
    else {
      timezeoneOffset = Math.abs(timezeoneOffset);
      if(timezeoneOffset.toString().length == 1) 
        timeZone = "+0" + timezeoneOffset + ":00";
      else {
        var number = timezeoneOffset.toString().split(".");
        var temp = "0." + number[1];
        timeZone = "+0" + parseInt("" + number[0]) + ":" + parseFloat(temp) * 60;
      }
    }
  
    return date.getFullYear() + "-" + month + "-" + day + "T" + hours + ":" + mins + ":" + sec + "." + milSec + timeZone;
  }

  getToTimeZonedDate(date) {
    var day, month, hours, mins, sec, milSec, timeZone;

    if(date.getDate().toString().length == 1)
      day = "0" + date.getDate();
    else
      day = date.getDate();

    if((date.getMonth() + 1).toString().length == 1)
      month = "0" + (date.getMonth() + 1);
    else
      month = (date.getMonth() + 1);

    if(date.getHours() > 0) {
      if(date.getHours().toString().length == 1) 
        hours = "0" + date.getHours();
      else
        hours = date.getHours();
    }
    else 
      hours = 23;

    if(date.getMinutes() > 0) {
      if(date.getMinutes().toString().length == 1)
        mins = "0" + date.getMinutes();
      else
        mins = date.getMinutes();
    }
    else 
      mins = 59;
    
    if(date.getSeconds() > 0) {
      if(date.getSeconds().toString().length == 1)
        sec = "0" + date.getSeconds();
      else
        sec = date.getSeconds();
    }
    else 
      sec = 59

    if(date.getMilliseconds() > 0) {
      if(date.getMilliseconds().toString().length == 1)
        milSec = "00" + date.getMilliseconds();
      else
        milSec = date.getMilliseconds();
    }
    else 
      milSec = 999;

      var timezeoneOffset = date.getTimezoneOffset() / 60;
    if(date.getTimezoneOffset() >= 0) {
      if(timezeoneOffset.toString().length == 1) 
        timeZone = "-0" + timezeoneOffset + ":00";
      else {
        var number = timezeoneOffset.toString().split(".");
        var temp = "0." + number[1];
        timeZone = "-0" + parseInt("" + number[0]) + ":" + parseFloat(temp) * 60;
      }
    }
    else {
      timezeoneOffset = Math.abs(timezeoneOffset);
      if(timezeoneOffset.toString().length == 1) 
        timeZone = "+0" + timezeoneOffset + ":00";
      else {
        var number = timezeoneOffset.toString().split(".");
        var temp = "0." + number[1];
        timeZone = "+0" + parseInt("" + number[0]) + ":" + parseFloat(temp) * 60;
      }
    } 

    return date.getFullYear() + "-" + month + "-" + day + "T" + hours + ":" + mins + ":" + sec + "." + milSec + timeZone;
  }

  escapeCSV (term) {
    if(term != undefined || term != null) {
      if (term.match && term.match(/,|"/))
        return `"${term.replace('"','""')}"`
      else 
        return term
    }
  }

  setLanguage(language) {
    localStorage.setItem("buck_language", language);
  }

  getLanguage() {
    var language = localStorage.getItem("buck_language");
    return language;
  }

  getMaskedAccountNumber(str): string {
    if (str) {
      var newStr = '', extraStr = '';
      str = str.replace(/\s/g, '');
  
      if(str.length < 5) {
        for(var i = 0; i < (5 - str.length); i++) {
          extraStr = extraStr + '*';
        }
  
        str = extraStr + str;
      }
      else
        extraStr = '';
  
      for(var j = 0; j < (str.length - 4); j++) {
        newStr = newStr + str[j].replace(str[j], "*")
      }
      for(var k = (str.length - 4); k < str.length; k++) {
        newStr = newStr + str[k];
      }
  
      return newStr;
    } else {
      console.log("[helper.service.ts] getMaskedAccountNumber() received a null string!");
      return '';
    }
  }

  getTwoPlacesAfterDecimal(str) {
    try {
      return parseFloat(str).toFixed(2);
    } catch(e) {
      return str;
    }
  }

  validateDates(startDate, endDate) {
    var errMsg = [], newStartDate = null, newEndDate = null;

    newStartDate = new Date(startDate);
    newEndDate = new Date(endDate);

    if(startDate == null || startDate == undefined) 
      errMsg.push("Please provide Start Date");
    
    if(endDate == null || endDate == undefined) 
      errMsg.push("Please provide Start Date");

    if(newStartDate > newEndDate)
      errMsg.push("The Start Date cannot appear after the End Date");

    return errMsg;
  }

  
  validateDateToISOString(date: Date | string): string {
    if(date) {
      const isFutureDate: boolean = this.isFutureDate(date);
      const currentDateString: string = new Date().toISOString();
      const validDate: string = new Date(date).toISOString();
      if(isFutureDate) {
        return currentDateString;
      }    
      return validDate;
    }
    return "";
  }

  formatAmount(amount) {
    return parseFloat(amount+"").toLocaleString(undefined,{minimumFractionDigits: 2, maximumFractionDigits: 6})
  }

  formatCount(amount) {
    return parseFloat(amount+"").toLocaleString(undefined,{minimumFractionDigits: 0, maximumFractionDigits: 6})
  }

  formatCurrency(amount) {
    return parseFloat(amount+"").toLocaleString(undefined,{minimumFractionDigits: 2, maximumFractionDigits: 2})
  }

  checkTokenValidity(token) {
    var tokenExpiryDate, currentDate;
    tokenExpiryDate = token;
    currentDate = new Date();
    if(tokenExpiryDate > currentDate)
      return true;
    else if(tokenExpiryDate <= currentDate)
      return false;
    else
      return false;
  }

  
  logout() {
    this.auth.logout('email').subscribe(result => {
      this.router.navigateByUrl('/auth/login');
    }, err => {
      console.log(err);
      this.router.navigateByUrl('/auth/login');
    });
  }

  showAlertBox() {
    this.dialogsRef = this.dialogService.open(AlertBoxComponent, { context: {}, })
    .onClose.subscribe(response => {
      this.logout();
    })
  }

  //Sample Code for Asynchronous Report Download
  openWindow(header?: string, data?: any) {
    
    if(header == 'Download CSV Report') {
      data = this.csvReport
    }
    else if(header == 'Download PDF Report') {
      data = this.pdfReport
    }

    const context = {
      text: "",
      self: this
    };
    this.windowRef = this.windowService.open(WindowComponent, {
      title: header,
      context: data
    });

    this.windowRef["startDate"] = null;
    this.windowRef["endDate"] = null;

    this.windowRef.onClose.subscribe(otp => this.onClose());
    this.windowRef.stateChange.subscribe(state => this.stateChanged());
  }
  stateChanged() {
    var minimizeButton, maximizeButton;

    minimizeButton = document.querySelectorAll("nb-card-header button")[0];
    maximizeButton = document.querySelectorAll("nb-card-header button")[1];

    minimizeButton.disabled = true;
    maximizeButton.disabled = true;
  }

  onClose() {
  }

  getErrorsFromAPI(error: any): string[] {
    const errorMessages = [] as Array<string>;
    if(error && error.error) {
      if(error.error.errors?.length > 0) {
        for(var i = 0; i < error.error.errors.length; i++) {
          errorMessages.push("Error: " + error.error.errors[i].message);
        }
      }
      else {
        errorMessages.push("Error: " + error.error.message);
      }
    }
    return errorMessages;
  }

  isPastDate(date: Date): boolean {
    return date instanceof Date && date.getTime() < new Date().getTime();
  }
  
  isFutureDate(date: Date | string): boolean {
    return new Date(date).getTime() > new Date().getTime();
  }

  getDefaultPastDate(): Date {
    const defaultDate = new Date();
    defaultDate.setMonth(defaultDate.getMonth() - 1);
    return defaultDate;
  }

  showToast(title: string, msg: string, duration: number, status: NbComponentStatus, position?: NbGlobalPosition, destroyByClick?: boolean, evaIcon?: string) {
    if(evaIcon) {
      const icon: NbIconConfig = { icon: evaIcon, pack: 'eva' };
      this.toastrService.show(msg, title, { status, duration, position, destroyByClick, icon });
    }
    else {

      this.toastrService.show(msg, title, { status, duration, position, destroyByClick });
    }
  }

  configurePartyLegalName(party): string {
    return party?.legalName ? 
      (
        party?.legalName ? 
        party?.legalName : 
        party?.name
      ) :
      (
        party?.middleName ? 
        `${party?.lastName}, ${party?.firstName} ${party?.middleName}` : 
        `${party?.lastName}, ${party?.firstName}`
      ) 
  }

  sortParties(parties: any[], field?: string): any[] {
    console.log(parties.map(party => party.registeredAt))
    console.log(parties.sort((a, b) => <any>new Date(b.registeredAt) - <any>new Date(a.registeredAt)).map(party => party.registeredAt))
    if(field) {
      return parties.sort((a, b) => a[field] - b[field] || a.kycStatus - b.kycStatus || a.name.localeCompare(b.name));
    }
    return parties.sort((a, b) => <any>new Date(b.registeredAt) - <any>new Date(a.registeredAt) || a.kycStatus - b.kycStatus || a.name.localeCompare(b.name));
  }

  generateCSVDownloadLinkFromBlob(blob: Blob, fileName: string) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', fileName + '.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  generateCSVDownloadLinkFromString(csv: string, fileName: string) {
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = fileName;
    link.href = url;
    link.click();
    URL.revokeObjectURL(url);
  }

  base64ToCsv(base64: string): string {
    const byteArray = Uint8Array.from(atob(base64), c => c.charCodeAt(0));
    return new TextDecoder().decode(byteArray);
  }

  getCurrentDateFromISOString(dateTime: string): string {
    const currentDate = new Date(dateTime);
    const dateOnly = currentDate.toISOString().split("T")[0];
    return dateOnly;
  }

  getCurrentDateString(): string {
    const currentDate = new Date();
    const dateOnly = currentDate.toISOString().split("T")[0];
    return dateOnly;
  }

  removeAllSubstringOccurences(originalStr: string, subStr: string): string {
    if (originalStr && subStr && originalStr.includes(subStr)) {
      return originalStr.replace(new RegExp(subStr, "g"), "");
    } else {
      return originalStr;
    }
  }

  convertDateStringsToDate(dateString): Date {
    var date: Date;
    if(dateString) {
      date = new Date(dateString);
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      return date;
    }
    else {
      return dateString;
    }
  }

  offsetDateTimezone(date: Date): Date {
    const timeSeparator = "T";
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
    const isoDateString = date?.toISOString()?.split(timeSeparator)?.[0];
    
    if(isoDateString?.match(isoDateRegex)) {
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    }
    
    return date;
  }

  convertDateFormat(date: Date): string {
    const timeSeparator = "T";
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
    const isoDateString = date?.toISOString()?.split(timeSeparator)?.[0];
    if(isoDateString) {
      return isoDateString;
    } else {
      return date.toString();
    }
  }
  
  setDateToStartOfDay(date: Date): Date {
    if(date) {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0, 0, 0, 0
      );
    }
    else {
      return date;
    }
  }

  setDateToEndOfDay(date: Date): Date {
    if(date) {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23, 59, 59, 999
      );
    }
    else {
      return date;
    }
  }
}
