export class ApiGetAccountParams {

	constructor(
		public partyId?: string,
		public accountNumber?: number,
		public accountName?: string,
		public currencyCode?: string,
		public countryCode?: string,
		public accountProviderId?: string,
		public buckzyAccountType?: string,
		public providerAccountNumber?: string,
		public isSettlementAccount?: boolean,
		public includeInactiveAccounts?: boolean,
		public page?: number,
		public size?: number,
		public includedVirtualAccount?: boolean,
		public underlying?: boolean,
	) {
		this.underlying = underlying ?? true;
		this.includeInactiveAccounts = includeInactiveAccounts || false; 
		this.page = page || 0;
		this.size = size || 10;
	}
}