<nb-alert status="primary" *ngIf = "showAlert">
    <div *ngFor = "let msg of messages">
        {{msg}}
    </div>
</nb-alert>
<div [nbSpinner]="loading" nbSpinnerStatus="primary">
    <div *ngIf = "!showAttachments && !showScreen && response.currencyCode != 'CAD' && response.screen != 'Refund Amount' && !showDeals && title != 'Add Documents' && title != 'Attached Documents' && !showReportDownload && title != 'Add Document'">
        <div style="text-align: center;">
            <br>
            <h4>Account Details</h4>
        </div>
        <div style="text-align: center;">
            <nb-form-field >
                <input [(ngModel)]="response.nickName" style="text-align: center;width: 300px;margin-left: 40px;" type="text" nbInput placeholder="Nick Name" [disabled]="!editModeForNickName">
                <button nbSuffix nbButton ghost (click)="editNickNameToggle()">
                <nb-icon [icon]="editModeForNickName ? 'save-outline' : 'edit-outline'" 
                        pack="eva"
                        [attr.aria-label]="editModeForNickName ? 'Nick Name' : 'Edit Nick Name'">
                </nb-icon>
                </button>
            </nb-form-field>
        </div>
        <br>
        <p>To load the account, please send the amount to the following bank account:</p>
        <br>
        <div style="line-height: 2;">
            <div class="row">
                <div class="col">
                    <label style="font-weight: 600;" class="myLabel">Bank Account Name</label>
                </div>
                <div class="col">
                    <!-- <label>{{response.walletId}}</label> -->
                    <input type="text" fullWidth nbInput value = "{{response?.financialInstitutionDetails?.accountName}}" disabled style="background-color: transparent; color: dimgray; font-weight: normal; border: none; float: right;" class="myInput">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label style="font-weight: 600;" class="myLabel">Account Number</label>
                </div>
                <div class="col">
                    <input type="text" fullWidth nbInput value = "{{response?.financialInstitutionDetails?.accountNumber}}" disabled style="background-color: transparent; color: dimgray; font-weight: normal; border: none; float: right;" class="myInput">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label style="font-weight: 600;" class="myLabel">Routing Number / ABA</label>
                </div>
                <div class="col">
                    <input type="text" fullWidth nbInput value = "{{response?.financialInstitutionDetails?.routingNumber}}" disabled  style="background-color: transparent; color: dimgray; font-weight: normal; border: none; float: right;"  class="myInput">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label style="font-weight: 600;" class="myLabel">Swift Code</label>
                </div>
                <div class="col">
                    <input type="text" fullWidth nbInput value = "{{response?.financialInstitutionDetails?.swiftCode}}" disabled style="background-color: transparent; color: dimgray; font-weight: normal; border: none; float: right;" class="myInput">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label style="font-weight: 600;" class="myLabel">Bank Name</label>
                </div>
                <div class="col">
                    <input type="text" fullWidth nbInput value = "{{response?.financialInstitutionDetails?.name}}" disabled style="background-color: transparent; color: dimgray; font-weight: normal; border: none; float: right;" class="myInput">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label style="font-weight: 600;" class="myLabel">Bank Address</label>
                </div>
                <div class="col">
                    <input type="text" fullWidth nbInput value = "{{response?.financialInstitutionDetails?.address?.addressLine1}} {{response?.financialInstitutionDetails?.address?.addressLine2}}" disabled style="background-color: transparent; color: dimgray; font-weight: normal; border: none; float: right;" class="myInput">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label style="font-weight: 600;" class="myLabel">Bank City</label>
                </div>
                <div class="col">
                    <input type="text" fullWidth nbInput value = "{{response?.financialInstitutionDetails?.address?.city}}" disabled style="background-color: transparent; color: dimgray; font-weight: normal; border: none; float: right;" class="myInput">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label style="font-weight: 600;" class="myLabel">Bank State</label>
                </div>
                <div class="col">
                    <input type="text" fullWidth nbInput value = "{{response?.financialInstitutionDetails?.address?.province}}" disabled style="background-color: transparent; color: dimgray; font-weight: normal; border: none; float: right;" class="myInput">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label style="font-weight: 600;" class="myLabel">Bank Postal Code</label>
                </div>
                <div class="col">
                    <input type="text" fullWidth nbInput value = "{{response?.financialInstitutionDetails?.address?.postalCode}}" disabled style="background-color: transparent; color: dimgray; font-weight: normal; border: none; float: right;" class="myInput">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label style="font-weight: 600;" class="myLabel">Bank Country</label>
                </div>
                <div class="col">
                    <input type="text" fullWidth nbInput value = "{{response?.financialInstitutionDetails?.address?.country}}" disabled style="background-color: transparent; color: dimgray; font-weight: normal; border: none; float: right;" class="myInput">
                </div>
            </div>
        </div>
        <br>
        <p>The amount should be credited in the account shortly after the amount is deposited in the linked account. In case, the funds are not credited within 72 hours, please contact us at <a href="mailto:customersupport@buckzy.net" class="color-blue">customersupport@buckzy.net</a></p>
        <br>
        <div style="text-align: center;">
            <button nbButton status = "primary" shape = "round" (click) = "copyToClipboard();">copy</button>
        </div>
    </div>
    <div *ngIf = "!showScreen && response.currencyCode == 'CAD' && response?.screen != 'Refund' && !showDeals">
        <div style="text-align: center;">
            <br>
            <h4>Account Details</h4>
        </div>
        <br>
        <p>To load the account with Interac e-Transfer, Please follow the following steps:</p>
        <br>
        <div style="line-height: 2;">
            <div class="row">
                <div class="col">
                    <label class="myLabel" style="font-weight: 600;">1. Log in to your online banking</label>
                </div>
                <div class="col">
                    <input type="text" fullWidth nbInput value = "" disabled style="background-color: transparent; color: dimgray; font-weight: normal; border: none; float: right;" class="myInput">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="myLabel" style="font-weight: 600;">2. Set your recipient as Buckzywallet</label>
                </div>
                <div class="col">
                    <input type="text" fullWidth nbInput value = "" disabled style="background-color: transparent; color: dimgray; font-weight: normal; border: none; float: right;" class="myInput">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="myLabel" style="font-weight: 600;">3. Set recipient email</label>
                </div>
                <div class="col">
                    <input type="text" fullWidth nbInput value = "null" disabled style="background-color: transparent; color: dimgray; font-weight: normal; border: none; float: right;" class="myInput">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="myLabel" style="font-weight: 600;">4. Set your Security Question</label>
                </div>
                <div class="col">
                    <input type="text" fullWidth nbInput value = "null" disabled style="background-color: transparent; color: dimgray; font-weight: normal; border: none; float: right;" class="myInput">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="myLabel" style="font-weight: 600;">5. Set your Security Answer</label>
                </div>
                <div class="col">
                    <input type="text" fullWidth nbInput value = "null" disabled style="background-color: transparent; color: dimgray; font-weight: normal; border: none; float: right;" class="myInput">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="myLabel" style="font-weight: 600;">6. Send!</label>
                </div>
            </div>
        </div>
        <br>
        <p>The amount should be credited in the account within 1 hour. In case if you do not receive the funds within 1 hour, please contact us at <a href="mailto:customersupport@buckzy.net" class="color-blue">customersupport@buckzy.net</a></p>
        <br>
        <div style="text-align: center;">
            <button nbButton status = "primary" shape = "round" (click) = "copyToClipboard();">copy</button>
        </div>
    </div>
    <div *ngIf = "showScreen && !showDeals">
        <label style="font-weight: bold;">Select Row #</label><br>
        <nb-select [(ngModel)] = 'rowNumber' (ngModelChange)= "showData();" placeholder = "Row #">
            <nb-option *ngFor = "let row of rows" value = "{{row.value}}">{{row.value}}</nb-option>
        </nb-select>
        <br><br>
        <table id = "myTable" *ngIf = "showSummary">
            <thead class="top-bottom-border">
              <tr>
                <th scope="col" style="padding-left: 10px; padding-right: 10px;">
                    Missing Fields
                </th>
                <th scope="col" style="padding-left: 10px; padding-right: 10px;">
                    Invalid Data Fields
                </th>
              </tr>
            </thead>
            <tbody class="report">
              <tr class="bottom-border dynamic-rows">
                <td>
                    Debtor Country
                </td>
                <td>
                    Creditor Type
                </td>
              </tr>
              <tr class="bottom-border dynamic-rows">
                <td>
                    Debtor Type
                </td>
                <td>
                    Creditor Province
                </td>
              </tr>
            </tbody>
          </table>
    </div>
    <div *ngIf = "response?.screen == 'Refund Amount' && !showDeals">
        <div>
            <div class="row">
                <div class="col">
                    <label style="font-weight: 600;">Refund Amount</label>
                </div>
            </div>
            <div class="row">
                <div class="col-1" style="padding-top: 10px;">
                    <!-- <input nbInput [(ngModel)]="response.currency" disabled style="max-width: 100%;"> -->
                    <label style="font-weight: 600;">{{response?.data?.currency}}</label>
                </div>
                <div class="col" style="padding-left: 20px;">
                    <input nbInput [(ngModel)]="amt" style="max-width: 100%;">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-2" style="padding-top: 10px;">
                    <label style="font-weight: 600;">Comments</label>
                </div>
                <div class="col">
                    <textarea nbInput fullWidth [(ngModel)]="comments"></textarea>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <button nbButton size = "small" (click) = "getAmount(amt, comments);" status = "primary">Refund Payment</button>
                </div>
                <div class="col">
                    <button nbButton size = "small" style="float: right;" outline status = "primary" (click) = "closeWindow();">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    
    <div *ngIf = "showDeals">
        <table id="myTable">
            <thead class="top-bottom-border">
              <tr class="font-size-14">
                <th scope="col" *ngFor = "let column of columns" class="header-padding">
                  {{column}}
                </th>
              </tr>
            </thead>
            <tbody class="report">
              <tr class="bottom-border dynamic-rows" *ngFor = "let item of response; let i = index;">
                <td class="td-decorate">
                    {{item?.dealId}}
                </td>

                <td class="td-decorate">
                    {{helperService.getFormattedDateTime(item?.closingAt)}}
                </td>

                <td class="td-decorate">
                    {{helperService.getFormattedDateTime(item?.expiredAt)}}
                </td>

                <td class="td-decorate">
                    {{item?.balanceStatus}}
                </td>

                <td class="td-decorate">
                    {{item?.status}}
                </td>

                <td class="td-decorate">
                    {{item?.active}}
                </td>

                <td class="td-decorate">
                    {{item?.sellCurrencyCode}} {{item?.sellAmount}}
                </td>

                <td class="td-decorate">
                    {{item?.rate}}
                </td>

                <td class="td-decorate">
                    {{item?.buyCurrencyCode}} {{item?.buyAmount}}
                </td>

                <td class="td-decorate">
                    {{item?.buyCurrencyCode}} {{item?.availableBalance}}
                </td>
                <td>
                    <button nbButton status = "primary" shape = "round" size = "small" (click) = "selectFxDeal(item);">Select Deal</button>
                </td>
              </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf = "title == 'Add Documents' || title == 'Add Document'">
        <div class="row justify-content-center file-types" *ngIf="canSelectDocumentType">
            <div class="col file-types-col">
                <nb-select fullWidth [(ngModel)]="fileType" placeholder="Select Document Type" (selectedChange)="handleFileType($event)">
                    <nb-option *ngFor="let type of fileTypes | async" value="{{ type.code }}">
                      {{ type.description }}
                    </nb-option>
                </nb-select>
            </div>
        </div>
        <div class="uploadfilecontainer" #dragDropContainer buckDragDropDirective (onFileDropped)="uploadFile($event)" *ngIf="fileType || !canSelectDocumentType">
            <input hidden type="file" #fileInput (change)="uploadFile($event)" accept=".pdf">
            <div *ngIf="fileName.length==0" class="text-center vertical-align-document-text">
                <h6>{{'pages.transactionHistory.dragDropLabel' | translate}}</h6>
                <p>{{'pages.transactionHistory.orLabel' | translate}}</p>   
                <button nbButton shape="rectangle" status="info" size="medium" (click)="fileInput.click()" [disabled] = "disabledUpload">Browse</button>
            </div>
            <div  class="text-center mt-4" *ngFor="let file of fileName | slice: 0 : 1;let i= index">
                <h6>{{ file }}</h6>    
                <h6>Do you wish to continue to upload the file?</h6>
                <div class="row">
                    <div class="col">
                        <div class="mt-3"><button nbButton shape="round" status="info" (click) = "sendFile()">Yes</button></div>
                    </div>
                    <div class="col">
                        <div class="mt-3"><button nbButton shape="round" class="no_background" (click)="deleteAttachment(i)">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div *ngIf = "title == 'Attached Documents'">
        <table id="myTable">
            <thead class="top-bottom-border">
              <tr class="font-size-14 report">
                <th scope="col" *ngFor = "let column of columns" class="header-padding">
                  {{column}}
                </th>
              </tr>
            </thead>
            <tbody class="report">
              <tr class="bottom-border dynamic-rows" *ngFor = "let item of response; let i = index;">
                <td class="td-decorate">
                    {{item?.id}}
                </td>

                <td class="td-decorate">
                    {{item?.submittedAt}}
                </td>

                <td class="td-decorate">
                    {{item?.type}}
                </td>

                <td class="td-decorate">
                    {{item?.fileName}}
                </td>

                <td class="td-decorate">
                    {{item?.submittedBy}}
                </td>
                <td>
                    <button nbButton status = "primary" shape = "round" size = "small" (click) = "downloadFile(item);">
                        <nb-icon icon="download-outline"></nb-icon>
                    </button>
                </td>
              </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf = "showAttachments">
        <table class="rwd-table" id="myTable">
            <th *ngFor = "let column of attachmentCoumns" class="top-bottom-border center-align" style="line-height: 3;">
                {{column}}
              </th>
            <tbody class="report center-align">
                <tr class="bottom-border dynamic-rows">
                    <td class="padding-top-left-right">
                        July 30, 2021
                    </td>
                    <td class="padding-top-left-right">
                        Invoice.pdf
                    </td>
                    <td class="padding-top-left-right">
                        ABC
                    </td>
                    <td class="padding-top-left-right">
                        <button nbButton status = "primary" shape = "round" size = "small">
                            <nb-icon icon="download-outline"></nb-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf = "showReportDownload">
        <table class="rwd-table" id="myTable">
            <th *ngFor = "let column of reportDownloadColumns" class="top-bottom-border center-align" style="line-height: 3;">
                {{column}}
              </th>
            <tbody class="report">
                <tr class="bottom-border dynamic-rows" *ngFor = "let data of reportDownloadResponse">
                    <td class="padding-top-left-right">
                        {{data.id}}
                    </td>
                    <td class="padding-top-left-right">
                        {{data.name}}
                    </td>
                    <td class="padding-top-left-right">
                        {{data.createdOn}}
                    </td>
                    <td class="padding-top-left-right">
                        {{data.type}}
                    </td>
                    <td class="padding-top-left-right">
                        {{data.format}}
                    </td>
                    <td class="padding-top-left-right">
                        {{data.status}}
                    </td>
                    <td class="padding-top-left-right">
                        <button nbButton status = "primary" shape = "round" size = "small">
                            <nb-icon icon="download-outline"></nb-icon>
                        </button>
                        &nbsp;&nbsp;
                        <button nbButton status = "danger" shape = "round" size = "small">
                            <nb-icon icon="trash-outline"></nb-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>