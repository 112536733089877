import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountSelectorService {

  private accountSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private updateAccountRequest: Subject<string> = new Subject<string>();

  constructor() { }

  notifyAccountChange(selectedAccount): void {
    // console.log(`Notifying all subscribers of a global Account change to accountId: ${selectedAccount.accountId} [currencyCode:${selectedAccount.currencyCode} & balance:${selectedAccount.balance}].`);
    this.accountSubject.next(selectedAccount);
  }

  getSelectedAccount(): Subject<any> {
    // console.log("getSelectedAccount", this.accountSubject);
    return this.accountSubject;
  }

  updateOfAccountsNeeded(accountId: string): void {
    this.updateAccountRequest.next(accountId);
  }

  accountUpdateRequested(): Subject<string> {
    return this.updateAccountRequest;
  }

}
