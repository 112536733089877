import { Component, OnInit } from '@angular/core';
import { ApiGetAccountParams } from 'app/models/clients/account/api-get-account-params.model';
import { AccountSelectorService } from 'app/services/account/account-selector.service';
import { PartyService } from 'app/services/party/party.service';

@Component({
  selector: 'account-selector',
  templateUrl: './account-selector.component.html',
  styleUrls: ['./account-selector.component.scss']
})
export class AccountSelectorComponent implements OnInit {

  accountsArray: any = [
    {
      currencyCode: '',
      accountId: '',
      balance: '0.00',
      financialInstitutionDetails: {
        countryCode : '' 
       },
     }
  ];

  selectedAccountIndex = 0;
  updatedAccountFetched = false;

  constructor(private accountSelectorService: AccountSelectorService, private partyService: PartyService) { }

  ngOnInit(): void {
    this.initiateAccountsArray(this.selectedAccountIndex);
    this.listenForUpdate();
  }

  fireSelectedAccount(accountIndex): void {
    console.log("Selected Account changed to: ", this.accountsArray[accountIndex]);
    this.accountSelectorService.notifyAccountChange(this.accountsArray[accountIndex]);
  }

  // index parameter is used for selecting a specific account as the selected (first/default) option after fetching the accounts. 
  initiateAccountsArray(index: number): void {
    this.partyService.getAccounts().subscribe(response => {
      this.accountsArray = response?.content;

      if (this.accountsArray?.length > 0) {
        this.accountSelectorService.notifyAccountChange(this.accountsArray[index]);
        this.updatedAccountFetched = true;
      } else {
        console.log("partyService.getAccounts() returned an empty response body!");
      }
    }, err => {
      console.log(err);
    });
  }

  listenForUpdate() {
    this.accountSelectorService.accountUpdateRequested().subscribe(accountId => {
      if (accountId) {
        this.updatedAccountFetched = false;

        // finding the index of updated acount object by accountId
        let index = this.accountsArray.findIndex( acc => acc.accountId == accountId );
        if (index) {
          this.initiateAccountsArray(index);
        } else {
          // if for some reason couldn't find the specific account index then pass the first account index, i.e. 0.
          this.initiateAccountsArray(0);
        }
       }
    });
  }

}
