
export class BuckzyDocument {

    id: number;
    type: string;
    identifier: string;
    expiryDate: string;
    
    ownerId: number;
    name: string;
    fileName: string;
    submittedAt: string;
    submittedBy: string;

    constructor(id?: number, type?: string, fileName?: string, submittedAt?: string, submittedBy?: string) {
        this.id = id;
        this.type = type;
        this.fileName = fileName;
        this.submittedAt = submittedAt;
        this.submittedBy = submittedBy;
    }

    public get getOwnerId() {
        return this.ownerId;
    }
    public set setOwnerId(value) {
        this.ownerId = value;
    }
    
    public get getName() {
        return this.name;
    }
    public set setName(value) {
        this.name = value;
    }

    public get getFileName() {
        return this.fileName;
    }
    public set setFileName(value) {
        this.fileName = value;
    }

    public get getSubmittedAt() {
        return this.submittedAt;
    }
    public set setSubmittedAt(value) {
        this.submittedAt = value;
    }

    public get getSubmittedBy() {
        return this.submittedBy;
    }
    public set setSubmittedBy(value) {
        this.submittedBy = value;
    }

    public get getId() {
        return this.id;
    }
    public set setId(value) {
        this.id = value;
    }

    public get getType() {
        return this.type;
    }
    public set setType(value) {
        this.type = value;
    }

    public get getIdentifier() {
        return this.identifier;
    }
    public set setIdentifier(value) {
        this.identifier = value;
    }

    public get getExpiryDate() {
        return this.expiryDate;
    }
    public set setExpiryDate(value) {
        this.expiryDate = value;
    }

}