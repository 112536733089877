export class WalletCurrency {
    public currencyCode: string;
    public currencyName: string;
    public unicdDecimal: any;
    public unicdHex: any;
    public countries: any[];
    public icon: string;

    constructor(currencyCode?: string, currencyName?: string) {
        this.currencyCode = currencyCode;
        this.currencyName = currencyName;
        this.icon = `assets/flags/${currencyCode?.toLowerCase()?.substring(0, 2)}.png`;
    }
}